import { Button, Input, Modal } from "antd";
import config from "config";
import _ from "lodash";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import orderHistoryActions from "slices/order-history";
import InputNumber from "../../../../components/number-input";
import { ORDER_DETAIL_ACTIONS } from "../../helper";
import "./_order-refund-dialog.scss";

const currencySymbols = config.CURRENCY_SYMBOL;

class OrderRefundDialog extends Component {
    static defaultProps = {
        title: " ",
        message: "",
        allowRequestClose: true,
        buttons: [
            {
                label: "Close",
                type: "default",
                onClick: () => {},
            },
        ],
    };

    constructor(props) {
        super(props);

        this.state = {
            display: false,
            refundAmount: 0,
            comment: "",
        };
    }

    handleRefundAmtChange = (value) => {
        this.setState({ refundAmount: value });
    };

    refundAllClick = () => {
        this.setState({ refundAmount: (this.props.totalPaid - this.props.totalRefunded).toFixed(2) });
    };

    handleCommentChange = (e) => {
        this.setState({ comment: e.target.value });
    };

    handleConfirmClick = () => {
        let data = {};
        if (this.state.refundAmount === 0) return;
        if (this.state.refundAmount !== 0) data["amt_refund"] = this.state.refundAmount;
        if (this.state.comment.length !== 0) data["comment"] = this.state.comment;
        if (Object.keys(data).length !== 0) {
            data["order_id"] = this.props.orderId;
            data["action"] = ORDER_DETAIL_ACTIONS.refund;

            // Call the submit function and handle the success scenario
            this.props
                .onSubmitClick(data)
                .then(() => {
                    // Submission successful, reset refund amount
                    this.setState({ refundAmount: 0, comment: "" });
                })
                .catch((error) => {
                    // Handle any submission error here
                    console.error("Refund submission failed", error);
                });

            this.props.setOrderHistoryState({ allowRefundSubmit: false });
            setTimeout(() => {
                this.props.setOrderHistoryState({ allowRefundSubmit: true });
            }, 5000);
        }
    };

    handleCancel = () => {
        this.props.onCancel && this.props.onCancel();
        this.setState({ refundAmount: 0, comment: "" });
    };

    renderTotalPaidTotalRefunded = () => {
        let { messages, totalPaid, totalRefunded } = this.props;
        return (
            <div className="column-wrapper order-refund-dialog-row">
                <div>
                    <div className="item header-text">{messages[0]}</div>
                    <div className="item"> {totalPaid}</div>
                </div>
                <div className="column2">
                    <div className="item header-text">{messages[1]}</div>
                    <div className="item"> {totalRefunded} </div>
                </div>
            </div>
        );
    };

    renderRefundActionRow = () => {
        const { messages, totalPaid, totalRefunded } = this.props;
        return (
            <div className="order-refund-dialog-row">
                <div className="item header-text">{messages[2]}</div>
                <div style={{ display: "flex" }} className="item">
                    <InputNumber
                        style={{ width: 338 }}
                        prefix={currencySymbols[this.props.storeCurrency]}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={2}
                        value={this.state.refundAmount}
                        min={0}
                        max={totalPaid - totalRefunded}
                        onChange={this.handleRefundAmtChange}
                    />
                    <Button className="header-text" onClick={this.refundAllClick}>
                        {messages[3]}
                    </Button>
                </div>
            </div>
        );
    };

    renderRemarkRow = () => {
        const { messages } = this.props;
        return (
            <div className="order-refund-dialog-row">
                <div className="item header-text">{messages[4]}</div>
                <div className="item">
                    <Input
                        value={this.state.comment}
                        placeholder={messages[4]}
                        onChange={(e) => this.handleCommentChange(e)}
                    />
                </div>
            </div>
        );
    };

    renderFooter = () => {
        return (
            <>
                <Button
                    onClick={() => {
                        this.handleCancel();
                    }}
                >
                    <FormattedMessage id="cancel" />
                </Button>
                <Button
                    disabled={!this.props.allowRefundSubmit}
                    onClick={() => {
                        this.handleConfirmClick();
                    }}
                >
                    <FormattedMessage id="submit" />
                </Button>
            </>
        );
    };

    render() {
        const { title } = this.props;
        return (
            <div>
                <Modal
                    visible={this.props.show}
                    title={title ? title : <div> &nbsp; </div>}
                    onCancel={this.handleCancel}
                    footer={this.renderFooter()}
                >
                    <div className="dialog-wrapper">
                        {this.renderTotalPaidTotalRefunded()}
                        {this.renderRefundActionRow()}
                        {this.renderRemarkRow()}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    storeCurrency: _.get(state, "store.storeCurrencyAndPricePlan.store_currency", "CAD"),
    allowRefundSubmit: _.get(state, "orders-page.allowRefundSubmit", false),
});

const mapDispatchToProps = {
    setOrderHistoryState: orderHistoryActions.setState,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrderRefundDialog));
