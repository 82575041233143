export default {
    "": "",
    " ": " ",
    yes: "是",
    no: "否",
    confirm: "確認",
    delete: "删除",
    delete_n_close: "刪除並關閉",
    ok: "確定",
    saved: "已保存",
    close: "關閉",
    done: "完成",
    language: "語言",
    filter: "篩選",
    product: "產品",
    store: "商家",
    loading: "正在加載...",
    manage_product: "管理產品",
    manage_order: "歷史訂單",
    sales_report: "銷售報告",
    setting: "設置",
    back: "返回",
    type_here: "在此輸入...",
    lan: "語言",
    dashboard: "銷售統計",
    dasher_info: "配送員信息",
    printer_setting: "打印機設置",
    app_setting: "App設置",
    store_availability_setting: "商家可用性設置",
    notification: "通知",
    warning: "警告",
    submit: "提交",
    cancel: "取消",
    comment: "備註",
    special_request: "特別要求",
    tel: "電話",
    addr: "地址",
    addr_short: "配送地址",
    street: "街邊停車",
    unit: "房間號",
    buzzer: "門鈴",
    city: "城市",
    province: "省份",
    country: "國家",
    not_set: "未設置",
    name: "名稱",
    name_date: "日期\\名稱",
    phone_number: "電話",
    email: "電子郵件",
    update: "編輯",
    skip: "跳過此版本",
    customer: "客戶",
    admin: "管理員",
    system: "系統",
    app_version: "版本",
    current: "當前",
    newest: "最新",
    Skipped: "跳過",
    app_info: "App信息",
    no_new_noti: "沒有新的通知",
    clear_all: "全部清除",
    select_date: "選擇日期",
    select_time: "選擇時間",
    select_all: "選擇全部",
    from: "從",
    to: "至",
    all: "所有",
    uncategorized_items: "未分類產品",
    left_margin: "左邊距",
    right_margin: "右邊距",
    top_margin: "頂部邊距",
    bottom_margin: "底部邊距",
    save: "保存",
    save_n_close: "保存並關閉",
    save_n_continue: "保存並繼續",
    localized: "本地化",
    primary: "第一語言",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    de: "Deutsch",
    fr: "法文",
    disabled: "禁用",
    margin: "邊距",
    top: "頂部",
    bottom: "底部",
    left: "左側",
    right: "右側",
    general: "通用",
    general_settings: "常規設置",
    qty: "數量",
    unable_to_load_any_data: "無法加載數據",
    status: "狀態",
    new: "新建",
    reload: "重新加載",
    selected: "已選擇",
    items: "產品",
    date: "日期",
    //shipping method
    delivery: "配送",
    pickup: "自取",
    eatin: "堂食",
    dine_in: "堂食",
    in_store: "店內",
    flatrate: "統一費率",
    free_shipping: "數字資產",
    quick_pay: "快速支付",
    //payment method
    pay_later: "當面刷卡支付",
    cash: "現金",
    credit_card: "信用卡",
    giftcard: "禮品卡",
    points: "積分",
    points_and_giftcard: "積分與禮品卡",
    wechat_pay: "微信支付",
    paypal_express: "PayPal",
    alipay: "支付寶",
    union_pay: "銀聯借記卡",
    union_pay_express: "銀聯信用卡",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "信用卡",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    braintree_google_merchant_id: "Google Merchant ID",
    visa: "Visa",
    master: "Master",
    american_express: "American Express",
    discovery: "Discovery",
    jcb: "JCB",
    maestro: "Maestro",
    etransfer: "電子或郵件轉賬",
    card_present: "持卡支付",
    payment_type_kiosk: "Kiosk payment",
    //status
    pending: "待處理",
    processing: "處理中",
    payment_review: "付款審查",
    completed: "完成",
    canceled: "已取消",
    closed: "已關閉",
    pending_payment: "待付款",
    holded: "持有",
    logout: "登出",
    logout_confirm_message: "您確定要註銷嗎？",
    force_logout_message: "您的賬戶已從另外一台設備上登錄或者已經超時。請重新登錄。",
    mark_as_paid: "標記為已支付",
    mark_as_unpaid: "標記為未支付",
    //detail
    subtotal: "商品總價",
    item_total: "商品小計",
    discount: "折扣",
    store_discount: "商家折扣",
    coupon_discount: "優惠券折扣",
    shipping_and_handling: "配送費",
    shipping_and_handling_with_distance: "配送費 ({distance})",
    total_before_tax: "稅前金額",
    tax: "稅",
    order_totals: "訂單總結",
    order_total: "總價",
    summary: "小結",
    gift_card_payment: "禮品卡支付",
    points_payment: "積分支付",
    "points_payment_with_points": "積分支付({points})",
    "giftcard_payment_with_giftcard": "禮品卡支付({card})",
    total_paid: "已付款",
    total_due: "應付款",
    tips: "小費",
    delivery_fee_with_distance: "配送費 ({distance})",
    dlv_fee: "配送費",
    CD: "代碼",
    store_data_error_message: "很抱歉，無法讀取商舖信息，請稍後再試。",
    //version control
    up_to_date: "最新",
    no_update_availabe: "沒有可用的更新",
    //app closing
    quit_confirm_message: "您確定要退出GoopterBiz嗎？",
    incorrect_password: "密碼錯誤",
    server_error: "服務器錯誤",
    //filter
    clear_fliter: "清除篩選條件",
    search: "搜索",
    first_name: "名字",
    last_name: "姓氏",
    max_amount: "最高金額",
    min_amount: "最低金額",
    today: "今天",
    tomorrow: "明天",
    yesterday: "昨天",
    this_week: "本週",
    last_week: "上週",
    last_two_weeks: "過去2週",
    this_month: "這個月",
    last_month: "上個月",
    this_year: "今年",
    last_year: "去年",
    last_seven_days: "過去7天",
    last_fourteen_days: "過去14天",
    last_thirty_days: "過去30天",
    last_365_days: "過去365天",
    enter_period: "輸入時間段",
    last: "末頁",
    now: "現在",
    mins: "分鐘",
    add_x_mins: "+ {x}分鐘",
    add_x_hrs: "+ {x}小時",
    set_exact_time: "設置確切時間",
    or: "或",
    //confirm_go_back
    confirm_go_back_message: "您確定要中止所有更改並退出此頁面嗎？",
    confirm_remove_changes: "確認刪除更改",
    //no internet
    no_internet_connection: "沒有網絡連接",
    enter_password: "輸入密碼",
    unlock: "解鎖",
    test_print_title: "正在打印",
    test_print_message: "如果未打印測試頁，則可能出現打印機錯誤。請檢查打印機，然後重試。",
    unstable_network: "網絡速度慢或不穩定，請檢查您的網絡連接",
    //error handling
    incorrect_username_or_password: "Incorrect Username or Password",
    account_locked: "你的帳號已被鎖定, 請聯繫Goopter解鎖你的帳號。",
    login_failed_try_later: "登入失敗，請稍後再試。",
    invalid_request_body: "無效的請求",
    unknow_host: "未知主機",
    over_refund: "退款金額不應大於總支付金額減去已退金額",
    partial_request_not_allowed: "此訂單不能部分退款",
    unexpect_error: "發生意外錯誤, 請稍後再試。",
    update_order_failed: "更新失敗，請稍後再試。",
    refund_not_settled: "付款交易已提交結算，但是尚未完成，請3小時後嘗試退款。",
    braintree_error_dialog: "無法完成此訂單的付款",
    live_order: "當前訂單",
    comments: "備註",
    sales_summary: "銷售匯總",
    show_more: "展開",
    show_less: "縮進",
    menu: "菜單",
    insufficient_permissions: "您的帳戶無權訪問此頁面。",
    save_changes: "保存更改",
    rate_and_review: "打分並且寫評語",
    rate_first_review_next: "先打分，再寫評語",
    tell_us_about_our_service: "請給我們的服務提下您的寶貴意見",
    click_to_select: "點擊選擇",
    validate: "驗證",
    unbind: "解除绑定",
    welcome: "歡迎",
    home: "主頁",
    change: "更改",
    changes: "變化",
    submitted: "已提交",
    submit_for_review: "提交申請",
    submit_application: "提交申請",
    pending_for_review: "待審核申請",
    remove_from_review: "撤銷申請",
    new_store_pending_review: "在線商鋪申請 - 待審核",
    submit_review_success_msg: "感謝您提交在線商鋪申請。我們將審核您的申請並在2個工作日內回覆您。",
    edit: "編輯",
    and: "和",
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thu: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期天",
    mo: "星期一",
    tu: "星期二",
    we: "星期三",
    th: "星期四",
    frd: "星期五",
    sa: "星期六",
    su: "星期天",
    choose: "選擇",
    add: "添加",
    action: "操作",
    enable: "顯示",
    enabled: "啟用",
    disable: "不顯示",
    view: "查看",
    attention: "提醒",
    error: "錯誤",
    first: "首頁",
    prev: "前一頁",
    next: "下一頁",
    reset: "重置",
    alert_are_you_sure: "提醒：你確定嗎?",
    alert_leaving_without_save: "警告：您在此頁面上有未保存的更改。您想離開此頁面並放棄更改嗎？",
    franchise_manager: "總店管理員",
    franchise_assistant: "總店助手",
    franchise_cashier: "收銀",
    store_manager: "店經理",
    store_assistant: "店助理",
    store_cashier: "店收銀",
    delivery_manager: "配送經理",
    order_manager: "訂單經理",
    delivery_staff: "配送員",
    super_user: "超級用戶",
    super_admin: "Super Admin",
    group_sale_manager: "團購銷售經理",
    group_sale_product_owner: "團購產品所有人",
    alert_no_result_found: "提醒：沒有搜索結果",
    alert_load_failed: "提醒：無法加載記錄，請稍後再試",
    copyright: "版權",
    all_rights_reserved: "© 2015-2019 Goopter Holdings Ltd. 版權所有.",
    on: "開啟",
    off: "關閉",
    coupon: "優惠券",
    groupsale: "團購",
    products: "產品",
    coupons: "優惠券",
    giftcards: "禮品卡",
    groupsales: "團購",
    product_detail: "產品詳情",
    coupon_detail: "優惠券詳情",
    giftcard_detail: "禮品卡詳情",
    groupsale_detail: "團購詳情",
    product_information: "產品信息",
    coupon_information: "優惠券信息",
    giftcard_information: "禮品卡信息",
    groupsale_informaton: "團購信息",
    product_name: "產品名稱",
    reward_coupon_code: "優惠券代碼",
    coupon_hint_message: "帶有優惠券信息的短信模版",
    coupon_expire_dt: "優惠券過期日",
    coupon_name: "优惠券名称",
    giftcard_name: "禮品卡名稱",
    groupsale_name: "團購名稱",
    product_type: "產品類型",
    coupon_type: "優惠券類型",
    giftcard_type: "禮品卡類型",
    groupsale_type: "團購種類",
    ko: "韓語",
    hi: "印度語",
    ja: "日本",
    order_type: "訂單類型",
    type: "訂單類型",
    payment_method: "付款方式",
    paylater: "稍後刷卡支付",
    free_payment: "免支付",
    unionpay_debit: "銀聯借記卡",
    unionpay_credit: "銀聯信用卡",
    previous: "上一頁",
    forget_password: "忘記密碼",
    phone_code_canada: "加拿大 +1",
    phone_code_united_states: "美国 +1",
    password_empty_feedback: "密碼不能為空!",
    c2c_setting: "跨國配送設置",
    store_front: "客戶端網頁",
    store_front_shared: "客戶端網頁(共享域名)",
    store_front_independent: "客戶端網頁(獨立域名)",
    sign_out: "退出",
    due_time: "要求時間",
    quick_add: "快加產品",
    pos_mode: "POS 模式",
    total: "Total",
    all_categories: "所有類別",
    copy_to_new_product: "複製到新產品",
    view_edit: "查看/編輯",
    product_copied: "已拷貝產品",
    product_copied_content: "產品複製成功。進行必要的更改，保存後會生成新產品。",
    update_price: "更改價格",
    update_stock: "更改庫存",
    update_availability: "更改有效性",
    update_tax: "更改稅率",
    set_enabled: "設置為啟用",
    set_disabled: "設置為禁用",
    products_deleted: "已刪除{count}個產品。",
    products_updated: "已更新{count}個產品。",
    products_deleted_message: "以下產品已成功刪除：{success}。",
    products_updated_message: "以下產品已成功更新：{success}。",
    increase_or_decrease_message: "提高或降低所選產品的價格。",
    enter_the_value: "請輸入金額",
    select_to_round: "自動調整價格",
    round_the_price: "價格取整",
    choose_percentage_amount: "選擇是否要按百分比或金額更新價格",
    by_percentage: "按百分比",
    by_amount: "按金額",
    no_rounding: "不捨入",
    round_to_dollar: "四捨五入到$1",
    round_to_one_decimal: "舍入到一位小數",
    round_to_two_decimals: "舍入到二位小數",
    set_amount: "設置金額",
    increase: "增加",
    decrease: "減少",
    confirm_delete_products: "刪除產品",
    confirm_delete_products_content: "您確定要刪除{count}個產品嗎？",
    confirm_enable_products: "啟用產品",
    confirm_enable_products_content: "您確定要啟用選中的{count}個產品嗎？",
    confirm_disable_products: "禁用產品",
    confirm_disable_products_content: "您確定要禁用選中的{count}個產品嗎?",
    size: "大小",
    number: "數量",
    color: "顏色",
    material: "材料",
    length: "長度",
    width: "寬度",
    scent: "香味",
    flavor: "口味",
    model: "款式",
    format: "格式",
    platform: "平台",
    addon: "附加",
    edition: "版本",
    presets: "預設值",
    cooking_style: "烹飪方式",
    option: "選項",
    item_title_missing_language_content:
        "該商品沒有 {changedLanguagesFullNames} 名字。 {languagesFullNames} 名字 {values}，將作為該商品的{changedLanguagesFullNames}名字。您確定要繼續進行這些更改嗎?",
    item_title_language_content:
        "產品名字已更改為{changed}，但在{languagesFullNames}中仍為{values}。確定要繼續這些更改嗎？",
    option_title_language_content:
        "選項名字已更改為{changed}，但在{languagesFullNames}中仍為{values}。確定要不進行這些更改就繼續嗎？",
    select_details_to_copy: "選擇要復制的詳細信息",
    message_disabled_warning:
        "您的通知設置已關閉；您因此將不會收到新的或更新的訂單通知。請確保啟用通知設置以得到通知。",
    dismiss: "關閉",
    discard: "放棄",
    images: "圖片",
    show_images: "显示图片",
    move_category: "移動分類目錄",
    move_category_confirm: "您確定要移動此分類目錄嗎？",
    upsell_products: "加售產品",
    recommend_a_good_restaurant: "為您挑選了一個好店",
    recommend_a_good_merchat: "為您挑選了一個好商家",
    recommend_a_good_product: "為您挑選了一個好物",
    guest_user: "匿名用戶",
    image_quality: "圖片質量",
    low: "低",
    medium: "中",
    high: "高",
    order_from: "訂單來自於",
    os: "操作系统",
    browser: "瀏覽器",
    ip_address: "IP",
    copy_what: "復制{what}",
    copied_to_clipboard_success: "{what}已復制",
    copied_to_clipboard_failed: "複製{what}失敗",
    success: "成功",
    failed: "失敗",
    no_record: "未找到記錄",
    try_other_filters: "嘗試更改過濾器或搜索詞",
    view_all_orders: "查看所有訂單",
    read_only_message: "您當前的權限對此頁面是只讀的，不能進行更改。",
    online: "可下單",
    offline: "不可下單",
    no_authorization: "您無權訪問此頁面",
    only_jpg_png: "只能上傳 JPG 和 PNG 文件。",
    must_be_lt_2m: "文件大小不得超過 2MB",
    hide_disabled_products: "隱藏禁用的產品",
    show_hidden_products: "顯示隱藏的產品",
    enabled_status: "啟用狀態",
    enabled_products: "啟用產品",
    disabled_products: "禁用產品",
    product_visibility: "產品可見性",
    visible_products: "可見產品",
    hidden_products: "隱藏產品",
    load_more: "加載更多",

    sign_in_welcome: "歡迎使用Goopter商家中心",
    sign_in_head_hint: "請輸入賬戶登錄.",
    login: "商家簽到",
    username: "用戶名",
    password: "密碼",
    signup: "Sign Up",
    forgot_password: "忘記密碼",
    by_email: "通過電子郵件",
    by_phone: "通過短信",
    signup_instead: "Sign Up Instead",
    signin_instead: "Sign In Instead",
    self_serve_signup: "Self-serve Sign Up",
    assisted_signup: "Assisted Sign Up",
    self_serve_signup_desc:
        "Set up your online store by yourself. Input your business information and product information on your own.",
    assisted_signup_desc:
        "Provide basic information about your business and let us contact you to assist you with your online store set up.",
    store_address: "Store Address",
    floor_suite_optional: "Floor/Suite (Optional)",
    business_type: "Business Type",
    go_back: "Go Back",
    verification_email_sent: "Verification Email Sent",
    verification_email_sent_desc:
        "An email with a verification link has been sent, please follow the directions in the email to confirm your account. ",
    store_sign_up: "店家註冊",
    price_plan_contact_details: "Price Plan & Contact Details",
    store_type_categories: "Store Type & Categories",
    contact_name: "Contact Name",
    contact_number: "Contact Number",
    number_of_locations: "Number of Locations",
    current_revenue_optional: "Current Revenue (Optional)",
    store_categories: "Store Categories",
    does_delivery: "Does Delivery",
    assisted_signup_complete: "Assisted Signup Complete",
    assisted_signup_complete_desc: "We will contact you to help you setup your store.",
    cashondelivery: "現金",
    paypal_pro: "PayPalPro",
    braintree: "Braintree",
    ccsave: "刷卡",
    online_card: "在線支付",
    giftcard_ponts: "GiftCard|Points",
    wechatpay: "微信",
    goopterexpress: "PayPal",
    free: "無支付",
    unionpay: "銀聯信用",
    unionpayexpress: "銀聯借記",
    printer: "打印機",
    staff: "員工",
    operation: "操作",
    hourly: "小時",
    attendance: "Attendance",
    export_type: "導出類型",
    includes_order_detail: "包括訂單詳細",
    show_report: "查看報表",
    print_report: "打印報表",
    export_report: "導出報表",
    tippings: "小費",
    staff_report: "員工報表",
    total_cnt: "數量",
    total_amt: "總金額",
    total_tips: "小費",
    time: "時間",
    amt: "金額",
    daily_summary: "每日總計",
    period_summary: "合計",
    please_select_a_printer: "請選擇打印機",
    report_type: "報表類型",
    normal_staff_report: "員工銷售詳細",
    staff_summary: "員工銷售匯總",
    customer_summary: "自助下單匯總",
    sales: "銷售",
    update_categories: "更改分類目錄",
    update_category_ids: "更改分類目錄",
    store_switch: "更改商家",
    store_switch_successful: "切換商家成功",
    switched_to_store: "切換到商家 {name}.",
    switch_approved: "切換到已批准的",
    switch_wip: "切換到還在批復中的",
    wip_store: "還在批復中的商家",
    approved_store: "已批复的商家",
    business_name: "商家名稱",
    business_bio: "商家介紹",
    business_address: "商家地址",
    address: "地址",
    address_line_1: "地址第一行",
    address_line_2: "地址第二行",
    postal_code: "郵編",
    contact_information: "聯繫信息",
    phone: "電話",
    twitter: "Twitter",
    instagram: "Instagram",
    facebook: "Facebook",
    website: "網頁",
    store_type_and_categories: "商家類型和分類",
    restaurant: "餐廳",
    shopping: "商店",
    service: "服務",
    time_zone: "時區",
    latitude: "緯度",
    longitude: "經度",
    store_information: "商店信息",
    store_information_description: "貴公司的名稱和聯繫信息將呈現在您的在線商舖上，並將出現在客戶收據上。",
    hour: "營業時間",
    store_images: "商家圖片",
    price_plan_and_currency: "價格計劃，稅號，貨幣設置",
    owner: "聯繫人",
    store_name: "商家名稱",
    seo_title: "搜索引擎優化",
    seo: "搜索引擎優化",
    seo_description:
        "指定搜索引擎用來為產品建立索引的鏈接和元數據字段。最佳做法是在元數據和元描述中都包含高價值關鍵字。",
    currency_vat_number: "貨幣和稅號",
    currency_vat_number_description:
        "指定搜索引擎用來為產品建立索引的鏈接和元數據字段。最佳做法是在元數據和元描述中都包含高價值關鍵字。",
    price_plan: "價格計劃",
    price_plan_description: "價格計劃決定您的在線商店的功能及費用。",
    meta_title: "元信息標題",
    meta_keywords: "元信息關鍵詞",
    meta_description: "元信息簡介",
    url_key: "元信息鍵",
    search_engine_optimization: "優化搜索引擎",
    currency: "貨幣",
    vat: "VAT號碼",
    details: "詳細",
    store_pa: "商家公告",
    store_pa_en_placeholder: "在此輸入",
    public_announcement_description: "輸入您希望客戶在主頁上看到的簡短公告。",
    branding: "品牌信息",
    branding_description: "您的品牌信息適用於收據，發票，預訂和市場營銷。",
    logo: "JPG 徽標",
    banner_desktop: "標題圖片(桌面版本)",
    banner_desktop_description: "此圖片出現在電腦瀏覽器主頁的標題部分。建議尺寸為1234 x 345像素。",
    banner_mobile: "標題圖片(移動版本)",
    banner_mobile_description: "此圖片出現在移動設備瀏覽器主頁的標題部分。建議尺寸為678 x 167像素。",
    store_images_description: "添加圖像以展示您的商店。您可以通過單擊查看圖片。",
    display_options: "顯示選項",
    product_display_format: "產品顯示格式",
    product_detail_display_format: "產品詳情顯示格式",
    product_detail_display_format0: "打開彈出",
    product_detail_display_format1: "在新標籤中打開全部詳情",
    product_detail_display_format2: "在同一窗口中打開所有詳情",
    product_detail_display_format3: "點擊圖片打開彈出, 點擊標題在新標籤里打開詳情",
    product_detail_display_format4: "點擊圖片打開彈出, 點擊標題在同一窗口中打開所有詳情",
    product_detail_display_format5: "點擊標題打開彈出, 點擊圖片在新標籤里打開詳情",
    product_detail_desc: "產品詳細信息顯示格式是用來定義當用戶單擊產品列表頁面上的產品時如何打開產品詳細信息頁面。",
    store_qty_display: "庫存數量顯示",
    store_qty_display_amount: "庫存數量顯示閥值",
    store_qty_display_tip: "決定何時顯示庫存數量",
    store_qty_display_amount_tip: "庫存小於指定值時顯示庫存:",
    sold_qty_display: "產品銷量顯示",
    sold_qty_display_amount: "產品銷量顯示閥值",
    sold_qty_display_tip: "確定何時顯示產品銷量。",
    sold_qty_display_amount_tip: "當銷量大於或等於該值時顯示產品銷量。",
    dont_show_stock_quantity: "不顯示庫存",
    always_show_stock_quantity: "一直顯示庫存",
    dont_show_sold_quantity: "不顯示銷量",
    always_show_sold_quantity: "一直顯示銷量",
    show_stock_quantity_when_under_certain_amount: "低於指定值時顯示庫存",
    show_sold_quantity_when_over_certain_amount: "當銷量超過指定值才顯示",
    regular: "標準格式",
    super_market_style: "大圖片格式",
    one_page_store: "單頁面格式",
    regular_style_hidden_cat: "標準格式隱藏目錄",
    horizontal_category_display: "橫向顯示目錄",
    no_store_info_and_review_tab: "沒有商家信息和評論頁",
    with_store_info_tab_no_review_tab: "有商家信息頁，無評論頁",
    with_store_info_and_review: "有商家信息和評論頁",
    product_display_languages: "產品顯示語言",
    default_language: "默認語言",
    store_display_style: "商家頁面顯示樣式",
    product_display_format_tip:
        "在手機瀏覽器中，超級市場風格默認顯示網格視圖的產品列表，並且能夠在網格視圖和列表視圖之間切換；常規樣式僅在列表視圖中顯示產品列表",
    pagination: "分頁功能",
    pagination_tip:
        "分頁功能通常支持150多種產品以上的商店，當分頁模式打開的時候，產品將根據所選類別加載，每頁限制為20個產品；當分頁模式關閉時，所有產品同時加載入頁面",
    show_product_place_holder_image: "顯示默認圖片",
    show_product_place_holder_image_tip: "如果未提供圖像，則顯示每個產品的默認圖像",
    hot_category_enabled: '顯示 "熱銷" 目錄',
    onsale_category_enabled: '顯示 "促銷" 目錄',
    hot_category_enabled_tip: "促銷類別顯示前十個最優惠的商品",
    onsale_category_enabled_tip: "熱銷類別顯示前十個銷量最大的商品",
    use_land: "使用定制首頁",
    use_land_tip:
        "添加一個定製首頁來吸引訪客的注意力。請按照定製首頁的語法或聯繫support@goopter.com，或致電778-379-7918尋求幫助。",
    payment_options: "支付選項",
    accept_credit_card: "接受信用卡支付",
    accept_cash: "接受現金支付",
    allow_pay_later: "接受稍後刷卡支付",
    allow_qr_checkout: "虛擬支付終端",
    setup_fee: "開戶費",
    monthly_fee: "月費",
    maximum_languages: "最多支持語言",
    price_range: "價錢範圍",
    quantity_range: "數量範圍",
    under_10: "低於 $10",
    "10_30": "$10-30",
    "30_60": "$30-60",
    "60_99": "$60-99",
    above_100: "高於 $100",
    value_to_points_ratio: "訂單金額與獎勵積分比例",
    points_to_value_ratio: "獎勵積分與貨幣單位的比率",
    points_to_value_ratio_tip:
        "獎勵積分與貨幣單位（$, ¥, €,等）的比例，表示多少積分相當於一個貨幣單位。比如該字段的值設置為10，則每10個獎勵積分將等於1個貨幣單位。 (即10獎勵積分=$1）",
    value_to_points_ratio_tip:
        "訂單金額(產品金額)與積分的比率表示根據客戶花費的金額獲得多少積分。它應該是一個介於0和999之間的數字。如果該字段的值設置為10，客戶的訂單金額為$50, 完成訂單後客戶將獲得500積分。",
    value_to_points_ratio_alert_integer: "價值與積分比必須是整數",
    value_to_points_ratio_alert_range: "價值與積分比需在0至999之間",
    allow_online_order: "允許網上下單",
    allow_online_order_tip: "Allow Online Order Tip",
    allow_reward_points_tip:
        "獎勵積分有助於提高客戶忠誠度並吸引新客戶。 要啟用獎勵積分，您需要在Goopter進行存款，請致電支持778-379-7918為您的商店開啟獎勵積分功能",
    allow_order_when_store_closed: "關店後允許下單",
    allow_preorder_time: "營業前多少分鐘開始接受預訂",
    anytime: "任何時候",
    not_allowed: "不允許預訂",
    tips_settings: "小費設置:",
    enable_tips: "啟用小費",
    no_tips: "無小費",
    order_limit: "觸發小費規則的訂單金額",
    hint_order_limit: "如果商品累計超過指定金額，那麼客戶端將安裝百分比顯示小費選項，否則將顯示金額顯示小費選項",
    default_percentage_values: "默認小費百分比值",
    hint_default_percentage_values: "訂單金額超過指定金額時的默認小費的百分比值(按配送，店內和自取的順序)",
    default_amount_values: "默認小費金額值",
    hint_default_amount_values: "訂單金額小於指定金額時的默認小費金額(按配送，店內和自取的順序)",
    tips_in_percentage: "小費的百分比選項",
    hint_tips_in_percentage: "訂單金額超過指定金額時的百分比選項, 默認值為 0, 10, 12, 15, 20",
    tips_in_dollar_values: "小費的金額選項",
    hint_tips_in_dollar_values: "訂單金額小於指定金額時的金額選項, 默認值為 0, 2, 3, 4, 5",
    settings_overview: "設置總覽",
    nav_dashboard: "控制面板",
    business_features: "商家特徵",
    restaurant_extras: "餐廳相關信息",
    store_configuration: "商家設置",
    allow_order_online_description: "您可以打開或關閉在線訂購開關。關閉後，商品仍將保留在您的在線商店中，但無法下單。",
    unpaid_order_cancellation: "在線支付類型未付款的訂單取消時間",
    unpaid_order_cancellation_desc:
        "在預定義的時間之後，如果支付類型為在線付款但在下訂單時未付款，則係統將自動取消訂單。",
    outdoor_seats: "戶外座位",
    accept_qr_checkout: "接受掃碼付款",
    accept_qr_tip: "掃碼支付是允許您的客戶掃描提供的二維碼在他們自己的手機上完成支付，為您的收銀員節省時間。",
    eatin_area_setting: "店內用餐設定",
    good_for_business: "商務優惠",
    estimate_preparation_time: "預計準備時間(分鐘)",
    estimate_preparation_tip: "準備時間是商家在接到訂單後準備訂單的平均時間。您可以根據您的情況更改此時間設置。",
    delivery_setting: "送貨設置",
    delivery_time_delta: "送貨時間範圍(分鐘)",
    delivery_time_delta_tip:
        "配送時間範圍設置是配送的時間增量(以分鐘為單位)。例如，設置為10可以產生可用的時間段如10:00-10:10，10:10-10:20. ..；設置為15可以產生可用的時間段如：10:00-10:15, 10:15-10:30…等。",
    allow_pick_up: "允許到店自取",
    allow_out_call: "允許上門服務",
    pickup_time_delta: "自取時間範圍(分鐘)",
    outcall_time_delta: "上門服務時間範圍(分鐘)",
    pickup_time_delta_tip:
        "自取時間範圍設置是自取的時間增量(以分鐘為單位)。例如，設置為10可以產生可用的時間段如10:00-10:10，10:10-10:20...；設置為15可以產生可用的時間段如：10:00-10:15, 10:15-10:30…等。",
    outcall_time_delta_tip:
        "上門服務時間範圍設置是上門服務的時間增量(以分鐘為單位)。例如，設置為10可以產生可用的時間段如10:00-10:10，10:10-10:20...；設置為15可以產生可用的時間段如：10:00-10:15, 10:15-10:30…等。",
    allow_up_sale: "允許加售",
    up_sale_tip:
        "加售是商家用來增加銷售和利潤的常用策略，通常選擇買家可能購買的相關產品作為加售的產品，這樣子買家可能會在結賬之前將這些產品添加到購物車裡。",
    min_delivery_amount: "最小送貨量",
    min_pickup_amt: "最少自取金額",
    store_features: "商家特色",
    nagative_number_alert: "必須是正數",
    "3rd_party_local_delivery_setting": "第三方同城配送設置:",
    prepare_order_threshold_desc:
        "開始準備時間“定義了商家通常提前多少時間為預定的訂單開始準備。 如果訂單未及時設置為“開始準備“，系統將顯示提醒對話框。",
    surcharge_label_name: "附加費名稱",
    surcharge_order_type_any: "所有訂單",
    surcharge_amount: "附加費金額",
    surcharge_type: "附加費類型",
    surcharge_min: "附加費最低金額",
    surcharge_max: "附加費最高金額",
    surcharge_tax_class: "附加費稅率",
    surcharge_order_type: "附加費訂單類型",
    instant_checkout: "Quick Pay 快速支付",
    language_tooltip_surcharge: "您可以在此菜單中找到已本地化的語言",
    language_tip:
        "如需查看、修改與語言相關的欄目請選擇對應的語言， 以下欄目支持多語言設置：商家名稱、商家描述、商家公告、商家折扣信息",
    notice_infomation: "公眾通告",
    last_publish_time: "最後一次發布時間",
    meta_title_tip: "元標題是出現在瀏覽器窗口頂部的文本。 您可以使用基於產品名稱的默認值，或根據需要進行更改",
    meta_title_tip_content_pages:
        "元標題是出現在瀏覽器窗口頂部的文本。您可以使用此頁面名稱作為的默認值，或根據需要更改它",
    meta_keywords_tip: "一些搜索引擎使用元關鍵字比別人更多。 輸入一些高價值的關鍵詞，以幫助產品獲得更多的知名度",
    meta_keywords_tip_content_pages:
        "某些搜索引擎比其他搜索引擎更多地使用元關鍵字。輸入幾個高價值的關鍵詞，幫助頁面獲得更多的搜索",
    meta_description_tip: "元描述是出現在搜索結果列表中的文本。 為獲得最佳效果，請輸入長度介於150-160個字符之間的描述",
    republish_tip: "您的商店只有在發布之後才能被消費者查看, 取消發布可以隱藏公共查看",
    store_menu_tip: "如果您的商店中有多個菜單，您可以選擇相應的菜單，並允許您的客戶在不同的菜單之間切換下單",
    store_type_categoreis_tip: "您最多可以選擇三類商店描述",
    vat_number_tip: "增值稅號用於在發票上顯示增值稅號碼。例如，GST號嗎",
    search_engine_optimization_tip:
        "搜索引擎優化部分指定搜索引擎用於索引產品的URL密鑰和元數據字段。 目前的SEO最佳做法是在元標題和元描述中加入高價值關鍵字",
    c2c_shipping_product_tip:
        "在產品上設置運費將覆蓋商家級別的運費設置，如果產品的運費和商家上設置的運費一致的話就無需在產品上設置運費",
    alert_time_overlapping: "輸入時間重疊",
    alert_update_failed: "更新失敗！請檢查您的輸入！",
    alert_success_publish: "發布成功！",
    alert_success_publish_no_delivery:
        "您的商鋪已成功發布，因為配送時間還沒有定義，系統關閉了您的送貨服務。如果需要開啟配送服務，請在設置中打開配送服務，然後添加配送時間。",
    alert_failed_publish: "發布失敗！",
    alert_success_unpublish: "取消發布成功！",
    alert_failed_unpublish: "取消發布失敗！",
    alert_select_store_type: "請選擇至少一個商家類型!",
    alert_success_submit_for_review: "提交審查!",
    alert_success_unsubmit: "未提交!",
    alert_failed_submit_for_review_failed: "提交審查失敗!",
    alert_failed_unsubmit: "無法提交",
    apply_new_branch: "申請新商家",
    new_store: "新商家",
    store_pending: "等待審核",
    store_rejected: "審核不通過",
    store_approved: "通過審核",
    store_prepare_for_submission: "準備提交",
    store_pending_approval: "等待審核",
    store_info: "商家信息",
    publishing: "發布中",
    publish: "公開",
    unpublish: "不發布",
    republish: "重新發布",
    unsubmit: "取消提交",
    store_status: "商家狀態： ",
    general_information: "基本信息",
    store_type: "商家類型",
    store_menu: "商家菜單",
    store_image: "商家圖片",
    open_hour: "營業時間",
    delivery_hour: "配送時間",
    service_information: "服務設置",
    order_notification: "訂單提示",
    payment_setting: "支付設置",
    discount_public_notice: "公告",
    account_information: "賬號信息",
    store_contact_number: "商家聯繫號碼",
    store_website_url: "商家網站URL",
    store_website_url_tip: "網站URL是與您的域名綁定的，如果您需要更改，請聯繫support@goopter.com或致電778-379-7918。",
    store_h5_url: "觸屏版網址",
    store_mobile_website_url_tip: "觸屏版網址",
    contact_person: "店主",
    currency_and_vat_number: "貨幣和VAT號碼",
    select_a_currency: "請選擇交易貨幣",
    vat_number: "VAT號碼",
    store_type_subtype: "商家類型和分類",
    no_image: "沒有圖片",
    image_hint_do_not: "這個產品沒有任何圖片",
    open_24_hours: "一周24小時營業",
    open: "開門",
    opens_at: "開門時間",
    closes_at: "關閉時間",
    add_hours: "添加時間",
    delivery_24_hours: "一周24小時配送",
    street_address: "街道地址",
    buzz: "Buzz #",
    timezone: "時區",
    post_code: "郵編",
    basic_setting: "基礎設置",
    is_hold_ordering: "停止網上下單",
    stop_order_minutes_before_close: "商家關閉的前x分鐘停止下單",
    order_expiration_limit: "自動取消未支付的訂單的等候時間(僅限於支付寶，微信，銀聯支付)",
    above_60: "高於 $60",
    allow_reward_points: "啟用獎勵積分",
    ranking: "排行",
    require_billing_address: "需要完整的賬單地址",
    require_billing_address_tip:
        "建議商家要求客戶提供帳單地址以開啟嚴格的賬單地址驗證，當不需要完整的帳單郵寄地址時，客戶只需要提供帳單地址的郵政編碼，請注意如果不需要完整的帳單地址發生欺詐的風險也會上升。商家需要為欺詐的交易自己承擔責任。",
    accept_wechat_pay: "接受微信支付",
    online_status: "在線狀態",
    allow_apple_pay: "接受Apple Pay",
    allow_take_out: "允許外帶",
    allow_in_store_purchase: "允許店內購買",
    allow_in_store_service: "允許店內服務",
    allow_eat_in: "允許店內用餐",
    require_table_number: "要求店內用餐時提供餐桌號碼",
    allow_delivery: "允許配送",
    delivery_distance: "固定收費交付距離",
    delivery_fee: "基本配送費",
    delivery_fee_tip: "基本配送費是在固定收費配送距離內收取的配送費用",
    extra_dollar_amount: "超出部分每公里金額",
    estimate_delivery_time: "預計送達時間 (分鐘)",
    max_delivery_distance: "最遠配送距離",
    allow_3rd_party: "使用第三方配送",
    minutes: "分鐘",
    free_services: "免費服務:",
    tv_available: "提供電視",
    wifi: "提供無線網",
    alcohol: "酒水",
    no_such_info: "沒有這個信息",
    beer_wine_only: "只提供啤酒和紅酒",
    full_bar: "全部提供",
    noise_level: "噪音等級",
    quiet: "安靜",
    average: "一般",
    loud: "熱鬧",
    very_loud: "吵鬧",
    environment: "環境",
    intimate: "私密",
    hipster: "時尚",
    classy: "優雅",
    upscale: "高檔",
    parking: "停車",
    free_lot: "免費停車位",
    paid: "付費停車",
    allow_waitlist: "允許等候",
    allow_office_delivery: "允許指定地址送貨",
    waiter_service: "有服務員",
    take_reservation: "允許訂位",
    bike_parking: "提供自行車停車位",
    good_for_group: "適合團體",
    good_for_kids: "適合孩童",
    casual_attire: "着裝",
    casual: "隨意",
    dressy: "正裝",
    notify_by_text_message: "短信提示",
    receive_order_by_sms: "啟用短信接收訂單提示",
    receive_sms_to: "接收短信的號碼：",
    notify_by_email: "電子郵件提示",
    notify_by_email_tip: "要打開電子郵件通知，請先在資料中提供有效的電子郵件",
    receive_order_by_email: "啟用電子郵件接收訂單提示",
    receive_email_to: "發郵件到：",
    notify_by_push: "啟用推送提示",
    notify_by_push_tip: "為了啟用推送通知功能，您需要下載並安裝Goper biz應用程序，然後使用您的管理員帳戶登錄",
    receive_order_by_phone: "通過蘋果或者安卓設備接收訂單提示",
    order_printing: "打印訂單",
    smart_printer_setting: "智能打印設置",
    auto_print_printer: "自動用一體化智能打印機打印",
    auto_print_browser: "自動用瀏覽器打印訂單",
    printer_language: "打印機語言",
    primary_language: "第一語言",
    secondary_language: "第二語言",
    select_a_language: "選擇一種語言",
    printer_selection: "打印機選擇",
    select_a_model: "選擇一種型號",
    gt6000sw: "GT6000SW",
    fcs10w: "FCS10W",
    printer_model: "打印機型號",
    printer_access: "進入打印機",
    no_flex_tip: "需要訂購Flex Plan才能訪問此功能",
    "customer_order_notification_by_text_message": "客戶訂單短信提醒",
    "order_notification_confirmation": "訂單通知驗證",
    "enable_notification_validation": "啟用通知接收人驗證",
    "enable_notification_validation_tip":
        "啟用“通知接收人驗證“後，系統將驗證在線用戶收到了訂單通知。如果系統在三次嘗試後仍無法通過驗證，則會向管理員用戶發送電子郵件和短信提醒。",
    flex: "交易佣金計劃",
    use_3rd_party_tip:
        "第三方運輸是使用第三方的運送服務，例如FedEx，UPS等。當您打開第三方運輸服務時，您需要在設置 - > 郵寄服務中設置配送",
    logo_resolution_tip:
        "徽標圖像將顯示在您的網上商店頂部。您只有上傳一個徽標，保存一個新的徽標將取代現有的徽標。我們建議採用正方形，分辨率為256px x 256px的jpg 或者png圖片。",
    header_resolution_tip:
        "此商標標題圖片將用於顯示您的網站頂部(電腦瀏覽器版本)，如果您不提供的話，將會有一個默認的圖像顯示在您的網站上。建議的尺寸是1920px x 190px像素的jpg 或者png圖片。上傳並保存新的圖片將替換現有的標題圖片。",
    header_resolution_mobile_tip:
        "此商標標題圖片將用於顯示在您的移動版本在線商店的頂部(觸摸屏智能手機或平板電腦)。建議的尺寸是1024px x 256px像素的jpg或者png圖片。上傳並保存新的圖片將替換現有的標題圖片。",
    store_images_info: "添加照片來展示您的商店。您可以通過單擊圖像並拖動來改變排序。",
    is_hold_ordering_tip: "打開這個開關可以暫停在線訂單",
    add_replace_image: "添加/替換圖片",
    add_image: "添加圖片",
    uploading: "上傳中...",
    store_logo: "JPG 格式商家徽標",
    store_logo_hint: "jpg格式的徽標是顯示在客戶網站上用的。請確保它是正方形的，最好有圓邊框。推薦尺寸為500px*500px。",
    qr_logo: "PNG 徽標",
    qr_logo_hint: "PNG格式的徽標是用在商家二維碼上的，應與JPG格式的徽標一致。請確保在圓圈的邊界外是透明的背景。",
    store_header: "商家標題圖片(桌面版本)",
    store_header_mobile: "商家標題圖片(移動版本)",
    store_photos: "商家圖片",
    alert_close_after: "關門時間必須早與下一個開門時間",
    alert_close_before_4am: "跨天營業的結束時間必須早於4am",
    alert_open_after: "營業時間必須早於關門時間",
    alert_empty_time: "無效時間",
    alert_min_working_time: "關門時間比開門時間至少要晚30分鐘",
    price_plan_id_26: "個性計劃",
    price_plan_id_22: "全面計劃",
    price_plan_id_21: "基本計劃",
    price_plan_id_20: "免費試用",
    price_plan_id_0: "基本計劃",
    landing_page: "登錄頁",
    landing_page_desktop: "桌面登錄頁",
    landing_page_mobile: "手機登錄頁",
    goopter_delivery: "Goopter 配送",
    store_id: "商家ID",
    auto_create_task: "新訂單自動創建配送任務",
    _placeholder: "在此輸入...",
    store_information_saved: "商店信息已成功更新。",
    general_setting: "綜合",
    taxes_setting: "稅",
    item_setting: "產品",
    business_hours: "營業時間",
    shipping_setting: "郵費設置",
    permission_setting: "權限",
    pickup_delivery_setting: "自取和配送設置",
    menu_setting: "菜單",
    self_serve_ordering_setting: "店內掃碼下單",
    availability_setting: "可用代碼和時間對照表",
    managed_stores_setting: "管理的商家",
    notifications_setting: "通知",
    public_notice_setting: "公告",
    other_setting: "其它",
    general_setting_description: "自定義和編輯信息，例如商店地址，聯繫方式等。添加一個有關您的商店的簡介。",
    taxes_setting_description: "",
    item_setting_description: "",
    business_hours_description: "讓客戶知道你們什麼時候營業。讓客戶知道你們何時提供配送。",
    shipping_setting_description: "啟用第三方配送服務，例如FedEX，UPS，設置免費配送金額等。",
    permission_setting_description: "管理不同類型的用戶的權限",
    pickup_delivery_setting_description: "自定義適合你們業務和客戶需求的自取和配送設置。",
    menu_setting_description: "創建多個菜單，更新菜單的分類；選擇每個菜單的有效訂單類型。",
    self_serve_ordering_setting_description: "提供自助服務，減少和客戶面對面的接觸。輕鬆創建並管理每個檯面的二維碼。",
    availability_setting_description: "讓客戶知道何時提供某種類型的菜單。指定不同菜單的可用日期和時間。",
    managed_stores_setting_description: "連鎖店總經理可以管理來自多個商舖的訂單。選擇您要管理的商舖。",
    notifications_setting_description: "如果您想接收訂單狀態和其它更改的通知，請啟用推送通知。",
    public_notice_setting_description: "輸入您希望客戶在主頁上看到的簡短公告。",
    select_order_types: "請選擇訂單類型",
    other_setting_description: "",
    payment_options_description: "啟用適用於您商店的支付方式，給您的客戶提供更多付款選擇可以增加銷售。",
    display_options_description: "自定義商舖的顯示設置，包括默認語言，顯示樣式，產品顯示格式，如何分頁等。",
    tips_settings_description: "按照百分比和金額定義的小費金額，適用於配送，自取和店內消費。",
    availability_settings: "可用性設置",
    availability_description:
        "可用性代碼提供了唯一的代碼，可以用來代表一周中每一天的可用時間。如果您的生意有產品只能在指定日期或者時間才能下單的話，則可以定義可用性代碼，並將其分配給相應的產品或分類目錄。",
    actions: "操作",
    code: "代碼",
    hours: "時間",
    business_hours_description_short: "讓客戶知道你們什麼時候營業。",
    delivery_hours: "配送時間",
    delivery_hours_description: "選擇配送時間",
    store_description: "商店描述",
    includes_tips: "包含小費信息",
    "show_tax_details": "顯示稅金明細",
    "tax_details": "[稅金明細]",
    "tax_total": "稅金總計",
    entire_sales_summary: "全店銷售匯總",
    entire_store_sales_detail: "全店銷售明細",
    staff_sales_detail: "員工銷售總結",
    self_order_sales_summary: "自助下單總結",
    allow_takeout: "允許自取",
    specify_pickup: "允許多個取貨點",
    specify_pickup_desc: "如果您有多個取貨點的話，請打開這個開關並且制定取貨地址和時間。",
    location_name: "區域名稱",
    days: "日期",
    delete_availability_code: "刪除有效時間代碼",
    availability: "時間表",
    add_pickup_availability: "添加自取時間",
    pickup_time_range: "自取時間範圍",
    pickup_time_range_desc:
        "自取時間範圍設置是自取的時間增量(分鐘)。例如，設置為10可以生成如下時間段，10:00-10:10, 10:10-10:20。",
    min_pickup_amount: "最少自取金額",
    estimated_preparation_time: "預計準備時間",
    estimated_preparation_time_desc: "準備時間是商家準備訂單的平均時間。您可以根據自己的情況更改此時間設置",
    specify_delivery: "基於地區的固定配送費",
    specify_delivery_desc:
        "如果您的配送時間和費用基於區域/城市，請打開此開關並定義配送區域，費用以及每個區域的配送時間。",
    zone_name: "配送區域名稱",
    add_delivery_availability: "添加配送時間表",
    flat_delivery_fee_distance: "平價配送距離",
    base_delivery_fee: "基本配送費",
    extra_dollar_per_km: "超過距離後的每公里配送費",
    delivery_time_range: "配送時間範圍",
    delivery_time_range_desc:
        "配送時間範圍設置是配送的時間增量(分鐘)。例如，設置為10可以生成如下時間段，10:00-10:10, 10:10-10:20。",
    fee_delivery_amount: "最少配送金額",
    free_delivery_amount: "免費配送金額",
    edit_zone: "編輯區域",
    add_time_slot: "添加時間段",
    are_you_sure_delete_slot: "確定要刪除此時間段嗎？",
    days_placeholder: "選擇日子",
    all_day: "全天",
    add_new_boundary: "添加區域",
    remove_selected_boundary: "刪除選中的區域 ",
    edit_boundary_data: "編輯區域數據",
    backup: "備份",
    restore: "恢復",
    restore_options: "恢復選項",
    restore_options_message_delivery: "請選擇一個選項來恢復您的配送區域和時間設置。",
    restore_options_message_pickup: "請選擇一個選項來恢復您的自取區域和時間設置。",
    overwrite_existing_records: "覆蓋現有記錄",
    append_to_existing_records: "追加到現有記錄後",
    choose_file: "選擇文件",
    add_availability_code: "添加時間代碼",
    create_new_code: "添加新代碼",
    enter_new_code: "輸入新代碼",
    enter_new_code_name: "輸入新代碼名稱",
    empty_code_input: "請輸入時間代碼",
    empty_code_name_input: "請輸入可用時間名稱",
    code_already_exists: "重複的時間代碼",
    use_a_new_code: "時間代碼已經存在。請輸入其它代碼。",
    unsaved_changes: "您有未保存的修改",
    sure_to_exit: "確定要退出嗎?",
    end_before_start_time: "結束時間必須晚與起送時間",
    please_change_time: "請更改您的時間設置",
    edit_availability: "更改時間代碼設置",
    missing_fields: "缺失的字段",
    missing_fields_message: "以下字段缺失: {missing}",
    missing_time_ranges: "時間輸入不能為空。",
    please_input_address: "請輸入地址..",
    self_serve_ordering: "自助下單(掃碼下單)",
    display_self_order: "顯示自助下單操作步驟",
    generate_qr: "生成二維碼",
    accept_self_serve: "接受店內自助下單",
    require_table_num: "自助訂單時需要桌號",
    order_confirmation: "訂單確認",
    require_due_time_for_in_store: "店內訂單需要就緒時間",
    require_due_time_for_in_store_description: "當此開關打開時，商家在確認每個店內訂單時需要設置預計就緒時間。",
    dine_in_confirmation: "店內訂單需要確認",
    dine_in_confirmation_description:
        "當此開關關閉時，商家將不需要在接受或拒絕店內訂單時進行確認。即只需一鍵即可接受或拒絕訂單。",
    allow_self_serve: "允許店內自助下單",
    allow_self_serve_description:
        "自助下單使您的客戶在店裡時可以使用自己的手機快速下單。您可以為每個企台生成一個二維碼，讓您的客戶可以使用掃碼下單功能。",
    print_your_qr: "企台號碼設置",
    print_your_qr_description:
        "設置企台數量，和在卡片上顯示的標籤，例如桌子，展位或櫃檯座位。系統可以為每個企台創建一個唯一的二維碼。只需打印代碼並將其放在每個企台上即可。",
    exclude_station_desc: "不包括企台號碼",
    num_stations: "企台數量",
    station_label: "企台標籤",
    discount_label: "特價標籤",
    custom_text_1: "自定義標籤1",
    custom_text_2: "自定義標籤2",
    start_number: "起始號碼",
    station_label_placeholder: "比如 桌號",
    custom_text_placeholder: "比如 Free Wi-Fi: Goopter-Wi-Fi",
    custom_text_placeholder_2: "比如 Wi-Fi Password: 12345678",
    card_example: "卡片示例",
    card_example_title: "商家名稱",
    card_example_quote: "在您自己的設備上下單，我們會將訂單交到您的手中",
    card_example_instructions: "请将摄像头对准二维码扫描以打开菜单",
    card_example_table: "桌號 42",
    order_from_phone: "通過您的手機下單",
    no_app_required: "無需下載App",
    open_phone_camera: "打開手機攝像頭",
    scan_qr_code: "掃碼看菜單",
    order_and_we_deliver: "您只管下單，我們會將訂單交到您的手中",
    order_again_qr: "要再次下單，請掃描二維碼",
    sync_cart_with_server: "購物車同步到服務器",
    sync_cart_with_server_description:
        "开启“与服务器同步购物车“将允许客户购物车中的商品与服务器自动同步。与服务器同步购物车可确保客户不会丢失购物车中的物品。",
    do_not_sync: "關閉同步",
    sync_always: "實時同步",
    sync_every_x_sec: "每隔x秒同步",
    categories: "分類目錄",
    category_ids: "分類目錄",
    store_switcher: "切換商家",
    publish_manager: "發布管理",
    last_published: "最近發布",
    last_updated: "最近更新",
    published: "已發布",
    out_sync: "不同步",
    in_sync: "已同步",
    current_status: "發布現狀",
    not_published: "尚未發布",
    publish_confirm: "當前在線商店的記錄在後台和客戶端之間不同步，您現在希望現在進行同步嗎？",
    jan: "1月",
    feb: "2月",
    mar: "3月",
    apr: "4月",
    may: "5月",
    jun: "6月",
    jul: "7月",
    aug: "8月",
    sep: "9月",
    oct: "10月",
    nov: "11月",
    dec: "12月",
    assign_items: "分配產品",
    categories_desc: "分類目錄可以用來組織和安排您的產品，反應在銷售報表上和將訂單分類打印到不同打印機上。",
    add_category: "添加目錄",
    assign_items_to: "指定產品到目錄：{category}",
    pickup_delivery: "自取及本地配送設置",
    assign_items_desc: "當前在別的目錄下的產品將被重新分配到這個目錄下。{count} 個產品將會被分配到目錄: {category} 。",
    type_to_search: "輸入要搜索的內容...",
    rename: "修改名稱",
    confirm_delete: "確認刪除",
    are_you_sure_delete_category: "您確定要刪除目錄：{category} 嗎？",
    renaming_category: "修改名稱：{category}",
    surcharge_settings: "附加費設置",
    surcharge_settings_description:
        "系統可以在結帳時自動將預定義的附加費應用於每個訂單。附加費通常用於支付處理訂單所產生的費用。",
    no_tax_goods: "免稅商品",
    food: "食品",
    gst_only: "只收GST",
    GST: "GST",
    shipping: "配送方式",
    taxable_goods: "加稅商品",
    category_title: "分類目錄名稱",
    time_code_setting: "有效時間設置",
    add_edit_category: "添加/刪除目錄",
    edit_category: "編輯分類目錄",
    parent_category: "上一級目錄",
    paypal_account_setting: "PayPal賬戶設置",
    paypal_payment_pro_tip:
        "在您的電商網站上使用Paypal Payment Pro 來接受信用卡付款，免開戶費和月費，您的客戶在結賬時無需離開您的網站。",
    paypal_express_checkout: "PayPal Express賬戶設置",
    paypal_express_checkout_tip:
        "在您的結賬頁面上增加'Paypal Express Checkout' 按鈕，吸引眾多喜歡用Paypal Express支付的消費者。",
    stripe_account_setting: "Stripe賬戶設置",
    stripe_account_setting_tip:
        "在您的電商網站上使用Stripe Payment來接受信用卡付款，您的客戶在結賬時只需要輸入信用卡號碼。",
    alipay_account_setting: "支付寶",
    braintree_setting: "BrainTree付款",
    braintree_setting_tip:
        "一次性無縫集成即可支持PayPal，Venmo(僅在美國), 信用卡和借記卡以及流行的數字錢包(如Apple Pay）的平台，吸引更多買家並推動更高的轉化率。",
    emt: "電子或郵件轉賬",
    emt_desc: "允許您的客戶先下訂單，然後使用電子或郵件轉賬向您付款。",
    emt_account: "電子或郵件轉賬賬戶",
    emt_password: "郵件轉賬的密碼(可選)",
    emt_password_hint:
        "如果您的 EMT 賬戶未啟用自動存款，請定義一個密碼，以便我們的系統提醒您的客戶用指定的支付密碼付款。",
    card_present_setting: "持卡支付",
    card_present_setting_tip: "持卡支付是客戶用信用卡或借記卡在支付POS機上完成交易的支付方式。",
    vendor: "供應商",
    integration_type: "Integration Type",
    access_token: "Access Token",
    device_ids: "Device ID(s)",
    pos_id: "POS ID",
    customer_ids: "Customer ID(s)",
    debug: "Debug",
    clover: "Clover",
    global_payment: "Global Payment",
    moneris: "Moneris",
    rest_pay_api: "REST Pay API",
    remote_pay_sdk: "Remote Pay SDK",
    public_key: "公鑰",
    private_key: "私鑰",
    merchant_name: "商家名稱",
    merchant_contact_number: "商家聯繫號碼",
    partner: "Partner",
    merchant_login: "商家登錄",
    api_username: "API用戶名",
    api_password: "API密碼",
    payment_action: "支付行動",
    authorization: "授權",
    allowed_credit_card_types: "允許使用的信用卡種類",
    mastercard: "MasterCard",
    discover: "Discover",
    switch_maestro: "Switch/Maestro",
    solo: "Solo",
    enable_this_pay_option: "启用此支付方式",
    title: "標題",
    api_key: "API Key",
    merchant_id: "商戶代碼",
    credential: "密鑰代碼",
    alipay_exclusive_account: "支付寶平台獨享賬戶",
    authorize_only: "只授權",
    authorize_and_capture: "授權並且獲取",
    wechat_account_setting: "微信賬戶設置",
    app_id: "App ID",
    discount_information: "折扣信息",
    store_announcement: "商家公告",
    store_account_information: "商家價格計劃:",
    onepage: "單頁計劃",
    free_trial: "免費試用",
    basic: "基本設置",
    bronze: "青銅計劃",
    silver: "白銀計劃",
    gold: "黃金計劃",
    platinum: "白金計劃",
    credit_card_tip:
        "要允許您的客戶使用信用卡付款，請打開此開關；如果您的在線商店上的需要讓客戶實時信用卡付款，請申請一個PayPal Pro帳戶，並啟用該服務。",
    require_bill_address: "要求帳單地址",
    require_bill_address_tip: "用戶使用信用卡付款時需要帳單地址。",
    pay_later_tip: "如果要允許客戶先下訂單然後再當面付款，請打開此開關。",
    cash_tip: "如果要允許客戶先下訂單然後再當面現金付款，請打開此開關。",
    virtual_terminal_tip:
        "虛擬支付終端是允許您的客戶掃描提供的二維碼在他們自己的手機上完成支付，為您的收銀員節省時間。",
    alipay_account_tip:
        "支付寶是全球第一大移動支付服務平台。要為您的在線商店開通AliPay在線支付，請申請一個在線支付帳戶，填寫賬戶信息，然後啟用此支付。",
    wechat_account_desc:
        "WeChat is the largest social media and messaging platform in the world. To support WeChat Pay online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    unionpay_credit_desc:
        "UnionPay is China’s top merchant card service provider. To support UnionPay credit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it. Please note, no password is required for the customer to make payment with a UnionPay credit card.",
    unionpay_debit_desc:
        "UnionPay debit card online payment requires user to input password for the payment. To support UnionPay debit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    time_code_setting_desc:
        "時間設置定義了何時可以訂購產品。產品上的時間設置優先於類別上的時間設置；如果您不需要特定的產品時間，請保留A",
    show_time_table: "顯示時間表",
    show_items: "顯示產品",
    hide_items: "隱藏",
    content_language: "內容語言",
    language_select_tip: "您可以在此菜單中找到已本地化的語言。",
    hide_time_table: " 隱藏時間表",
    discount_options: "折扣設置",
    off_l: "關",
    code_exists: "此代碼已使用",
    use_unique_code: "請用一個唯一的代碼",
    min_purchase: "最少購買金額",
    entire_cart: "整個購物車",
    select_products: "指定產品",
    any_product: "全部產品",
    select_product_categories: "指定分類目錄",
    use_per_cust: "每個客戶可用次數",
    duplicate: "創建拷貝",
    share_facebook: "通過臉書共享",
    share_twitter: "通過推特共享",
    share_email: "通過郵件共享",
    share_wechat: "通過微信共享",
    search_dot: "搜索...",
    create_disc_code: "創建折扣碼",
    discount_codes: "折扣碼",
    auto_discounts: "自動折扣",
    free_shipping_rule: "免郵費規則",
    filters: "篩選",
    filter_discount_codes: "刪選折扣碼",
    sort: "排序",
    sort_by: "排序",
    ascending: "升序",
    descending: "降序",
    newest_first: "最新在前",
    oldest_first: "最舊在前",
    low_to_high: "從低到高",
    high_to_low: "從高到低",
    a_to_z: "A-Z",
    z_to_a: "Z-A",
    item_name: "產品名稱",
    discount_to: "最大折扣",
    discount_from: "最小折扣",
    discount_type: "折扣類型",
    discount_type_tip:
        "通過從原始價格中減去一個百分比來折扣項目 折扣適用於購物車中的每個合格項目，例如：輸入10的折扣金額 - 折扣適用於購物車中的每個合格項目，例如：在折扣金額中輸入10 更換價格比原始價格低10美元. - 通過從購物車總額中減去百分比來折扣整個購物車 在折扣金額中輸入10以減去10％ 的總價",
    by_percent: "產品價格折扣的百分比",
    by_fixed: "每個產品的固定金額折扣",
    cart_fixed: "整個訂單的固定金額折扣",
    discount_range: "折扣範圍",
    date_start: "開始時間",
    date_expire: "過期日",
    apply_filters: "應用篩選器",
    discount_name: "折扣名稱",
    create_discount_code: "創建新折扣碼",
    discount_code: "折扣代碼",
    created_at: "創建時間",
    updated_at: "最近更改時間",
    discount_info: "折扣信息",
    publicly_available: "公開發布",
    enable_product: "啟用產品",
    uses_per_customer: "每個客戶可用次數",
    use_per_cust_tip:
        "確定屬於任何所選客戶群的同一註冊客戶可以使用優惠券代碼的次數，該設置不適用於作為沒有登陸的客戶群的成員的客人購物者，或對於沒有登錄賬戶的客戶，如果沒有限制，請將該字段留空",
    uses_per_coupon: "每張優惠券試用",
    use_per_coup_tip: "確定可以使用優惠券代碼的次數，如果沒有限制，請將該字段留空",
    min_order_amount: "最少訂單金額限制",
    min_amount_tip: "使用此優惠券的最低訂單金額限制",
    discount_amount: "折扣值",
    discount_rule: "折扣規則",
    apply_to_all: "應用到所有",
    by_products: "根據產品",
    by_categories: "根據分類",
    save_discount_code: "保存摺扣碼",
    apply_to_categories: "应用到选择的目录",
    apply_to_products: "应用到选择的商",
    choose_products: "选择产品",
    please_ensure_fill_all_required: "請確認填寫所有必須的欄目",
    delete_discount_code: "刪除折扣碼",
    are_you_sure: "提醒：你確定嗎?",
    delete_draft_confirmation: "您確定要刪除保存的臨時訂單嗎?",
    edit_discount_code: "編輯折扣碼",
    when_exceeding_distance_limit: "當超過距離限制時：",
    free_shipping_within_x: "在 {x} 公里內免費送貨； 超過距離收取 {x} km以上的配送費",
    charge_full_delivery_fee: "收取全額配送費",
    transaction_type: "交易類型",
    sku: "SKU",
    sku_long: "SKU (Stock Keeping Unit)",
    category: " 分類目錄",
    price: "價格",
    item_list: "產品列表",
    bulk_edit: "批量更改",
    create_item: "創建產品",
    available: "有效",
    sold_out_today: "今天售磬",
    sold_out_indefinitely: "永久售磬",
    normal_product: "普通產品",
    gift_card: "禮品卡",
    self_input: "自助輸入價格",
    call_vendor: "聯繫商家",
    group_purchase_product: "群接龍產品",
    items_filter: "產品刪選器",
    item: "產品",
    item_s: "產品",
    price_from: "價格從",
    price_to: "價格至",
    quantity_from: "庫存從",
    quantity_to: "庫存至",
    apply: "應用",
    reset_filters: "重置刪選器",
    item_images: "產品圖片",
    item_images_desc: "單擊縮略圖以查看圖片。將圖片拖放到最左邊可設置為產品封面圖片。",
    short_description: "簡介",
    description: "詳細介紹",
    item_type: "產品類型",
    item_title: "產品名稱",
    show_store_link: "顯示店鋪鏈接",
    store_link_tip: "打開開關以在商品詳細信息頁面上顯示您店舖的鏈接。關閉以從頁面中刪除您的店舖的鏈接。",
    hide_on_frontend: "在客戶端隱藏",
    is_hidden_tip: "打開開關可在客戶端隱藏本產品。接龍產品內仍可看到隱藏的產品。",
    max_sale_qty: "每單限購量",
    max_sale_qty_tip: "客戶可以在一個訂單中可購買的最大數量。如果沒有限制請設為0。",
    min_sale_qty: "最小起訂量",
    min_sale_qty_tip: "購買此商品時客戶必須訂購的最低數量。",
    special_price: "特價",
    special_from_date: "特價開始時間",
    special_to_date: "特價結束時間",
    price_margin: "利潤率",
    price_cost: "成本",
    price_profit: "利潤",
    barcode: "條形碼 (ISBN, UPC, GTIN 等.)",
    barcode_short: "條形碼",
    barcode_length_invalid: "條形碼需在{minlen}到{maxlen}個字符長。",
    barcode_already_in_use: "條形碼已被使用",
    barcode_not_unique: "此條碼已被其他產品使用",
    click_to_validate_barcode: "點擊驗證條形碼",
    validated: "已驗證",
    tax_class: "稅率",
    stock: "庫存",
    weight: "重量",
    start_date: "開始時間",
    end_date: "結束時間",
    created_date: "創建時間",
    available_time: "有效時間",
    item_info: "產品信息",
    options: "選項",
    preview_video: "預覽視頻",
    video: "視頻",
    drop_down: "下拉框",
    radio: "單選按鈕",
    checkbox: "複選框",
    multiple: "多選按鈕",
    option_title: "選項標題",
    option_type: "選項種類",
    min_select: "最少選擇數量",
    max_select: "最多選擇數量",
    quantity_input: "允許輸入選項值的數量",
    add_edit_option: "添加/編輯選項",
    add_option: "添加選項值",
    save_option: "保存選項",
    modify_options: "修改選項",
    remove_from_item: "從產品裡刪除",
    related_upsell_products: "相關產品和加售產品",
    fullfilment: "處理",
    related_products: "相關產品",
    related_products_desc: "看到此產品的客戶還將看到相關產品列表。",
    up_sell_products: "加售產品",
    up_sell_products_desc:
        "加售是商家提高收入和利潤的常見策略。通常，您可以把客戶可能會購買的相關產品作為加銷售產品。這樣子客戶可以在結帳之前將這些產品添加到購物車中。",
    delivery_discount: "配送 折扣",
    pickup_discount: "自取 折扣",
    new_user_discount: "新用戶折扣",
    minimum_amount: "最少金額",
    discount_condition: "折扣條件",
    exclude: "排除",
    delete_delivery_discount: "刪除配送折扣",
    add_rule: "增加條件",
    exclude_selected_products: "排除指定目錄",
    exclude_selected_categories: "排除指定產品",
    apply_selected_products: "应用于选定的产品",
    apply_selected_categories: "应用于选定的分类目录",
    include_selected_products: "包括選定的產品",
    include_selected_categories: "包括所選類別",
    percent: "Percent",
    amount: "金額",
    create_automatic_delivery_discount: "創建配送折扣規則",
    create_automatic_pickup_discount: "創建自取折扣規則",
    create_automatic_shipping_discount: "差距配送折扣規則",
    create_automatic_user_discount: "差距用戶折扣規則",
    save_auto_discount: "保存摺扣",
    update_discount: "更新條件",
    delivery_discounts: "配送 折扣",
    before_tax: "稅前",
    max_distance: "最大免費配送距離",
    when_exceeding_max_distance: "當超過距離限制時",
    distance: "距離",
    free_within: "免費配送距離",
    charge_over: "超過距離後收取配送費",
    user_reward_points: "新用戶獎勵積分",
    user_discount: "新用戶折扣",
    condition_type: "條件種類",
    user_discounts: "用戶折扣",
    shipping_discounts: "配送折扣",
    apply_to: "應用到",
    selected_products: "選擇產品",
    selected_categories: "選擇分類目錄",
    delete_user_account: "刪除用戶賬戶",
    include: "包括",
    delete_discount_rule: "刪除折扣規則",
    store_qr_code: "掃碼下單",
    would_share: "希望為您提供一個",
    off_discount_coupon: "折扣的優惠券代碼",
    may_store: "您可以用它來訂購",
    no_discount_codes: "沒有找到折扣代碼",
    create_new_code_option: "點擊'創建折扣碼'按鈕創建一個新的折扣碼",
    no_discount_rules: "沒有找到折扣規則",
    create_new_rule: "點擊'創建折扣規則'按鈕創建一個新的規則",
    min_amount_missing: "缺最少金額",
    discount_missing: "缺折扣欄目",
    name_missing: "缺名稱",
    missing_from_date: "缺起送日期",
    missing_to_date: "缺結束日期",
    distance_missing: "缺距離",
    missing_min_order: "缺最少訂單金額",
    products_missing: "您沒有選擇產品",
    categories_missing: "您沒有選擇分類目錄",
    category_title_empty_warning: "分類名稱不能為空。",
    used: "已用",
    pickup_discounts: "自取折扣",
    required_fields_warn: "帶 “*” 標誌的為必須欄目",
    field_required: "此字段是必需的",
    invalid_discount_between: "折扣值無效。折扣值應大於{dollar}{gt}{percent}且小於{dollar}{lt}{percent}。",
    invalid_discount_low: "折扣值无效。折扣值应大于{dollar}{gt}{percent}。",
    invalid_discount_high: "折扣值无效。折扣值应小于{dollar}{lt}{percent}。",
    discount_options_description:
        "折扣代碼一般是用於給指定的用戶提供折扣，使用折扣代碼可在結帳時獲得對應的折扣；自動折扣適用於所有客戶，只要客戶訂單符合定義的折扣規則即可自動獲得折扣。",
    order_ahead_days: "允许的预订天数",
    order_ahead_days_tip: "“允许的预订天数“定义了客户可以提前多少天进行预订。",
    notification_settings: "通知設置",
    menu_desc: "如果您的商店中有多個菜單，您可以選擇相應的菜單，並允許您的客戶在不同的菜單之間切換下單",
    add_new: "添加",
    available_time_setting: "有效时间设置",
    edit_menu: "编辑菜单",
    create_menu: "创建菜单",
    save_menu: "保存菜单",
    delete_menu: "删除菜单",
    please_fill_all_fields: "请填写必须的栏目",
    missing_name: "缺失名称",
    managed_stores: "多分店訂單管理",
    managed_stores_desc: "總店經理可以管理多分店的訂單，請選擇您想管理的分店。",
    managed_stores_description:
        "如果您要同時管理多個分店的訂單，可以選擇您要的分店。只有有權訪問多個分店的管理員用戶才能訪問此頁面。",
    arrange_products: "更改產品顯示順序",
    arranging_category: "更改{category}下的顯示順序",
    drag_drop_products: "拖放產品以更改顯示順序。",
    add_edit_item: "添加/編輯產品",
    option_title_desc: "這是該選項的名稱",
    option_type_desc: "這是您的可選項在App或網站上的顯示方式。",
    quantity_input_desc: "當啟用後，客戶就可以在商家頁面輸入選項值的數量",
    min_select_desc: "如果此選項為可選項，請設置為0，數字表明客戶必須選擇的最少的數量",
    option_table_header_price_desc: "在產品原價基礎上的變化 (例如: 2 = 比原價多$2, -4 = 比原價少$4, 0 =和原價一樣)",
    category_deleted: "分類目錄已成功刪除",
    product_updated: "產品信息已更新，如果要在您的在線商店中體現此更新，請重新發布。",
    product_created: "新產品已創建，如果要在您的在線商店中顯示此產品，請重新發布。",
    nm_placeholder: "請輸入選項名稱",
    share: "分享",
    share_product: "分享產品",
    product_url_copied: "產品已經拷貝到剪貼板",
    admin_users: "管理員帳戶",
    admin_users_desc: "您可以為每個員工設置帳戶及相應的權限，每個員工只能看到所需的內容。",
    active: "活動",
    inactive: "凍結",
    admin_role: "管理員角色",
    user_since: "登記時間",
    id: "ID",
    add_admin: "添加管理員",
    personal_information: "個人信息",
    country_code: "國家代碼",
    promo_code: "促銷代碼",
    registration_time: "註冊時間",
    wechat_bind: "微信綁定",
    permission_set: "權限設置",
    reset_password: "更改密碼",
    please_enter_new_password: "請輸入新密碼",
    password_length_check: "密碼必須至少有6個字符長",
    binded_user_id: "已綁定的用戶ID",
    permissions: "權限",
    permission_role: "權限/角色",
    current_store: "現在的商店",
    contact_details: "聯繫信息",
    contact_details_description:
        "所有授權的用戶都需要使用驗證碼來驗證自己的身份，該驗證碼將通過以下提供的電話號碼或電子郵件發送",
    change_password: "更改密碼",
    role: "角色",
    ordering_food: "訂單",
    edit_order: "修改訂單",
    payment: "支付",
    report: "報表",
    edit_personal_information: "編輯個人信息",
    edit_permissions: "編輯權限",
    deactivate_account: "停用帳戶",
    cannot_delete_user: "無法刪除用戶",
    cannot_delete_own_account: "您不能刪除自己的帳戶",
    delete_user: "刪除用戶",
    edit_account: "編輯帳戶",
    add_admin_user: "添加管理員用戶",
    activate_account: "激活帳戶",
    read_write: "讀/寫",
    read_only: "只讀",
    wechat_step_one: "步驟1：關注Goopter 微信公眾號",
    wechat_step_two: "步驟2： 使用微信掃描下面的二維碼訂閱微信訂單通知服務",
    cannot_access: "無法訪問",
    no_inactive_users: "沒有已停用戶",
    common: "通用",
    settings: "設置",
    user_profile: "用戶個人資料",
    public_notice: "公告",
    products_categories: "產品/分類目錄",
    order_management: "訂單管理",
    marketing: "市場營銷",
    store_info_review: "商家信息/評論",
    setting_general_info: "通用信息設置",
    product_review: "產品評論",
    store_review: "對商家評論",
    customer_review: "客戶評論",
    create_store: "創建商舖",
    setting_pickup_delivery: "自取及本地配送設置",
    setting_self_serve: "自助下單設置",
    setting_hours: "營業時間設置",
    setting_managed_stores: "管理分店設置",
    setting_order_notifications: "订单通知设置",
    setting_payments: "支付設置",
    setting_surcharge: "附加費設置",
    setting_item_availability: "產品可用性設置",
    setting_switch_store: "切換分店設置",
    setting_menu: "菜單設置",
    display_settings: "網站顯示設置",
    roles_permission_settings: "角色/權限設置",
    order_comments: "訂單備註",
    no_comments_message: "沒有訂單備註",
    added_order_comment: "添加訂單備註",
    order_history: "訂單歷史",
    order_export: "導出訂單",
    product_availability: "產品可用性",
    store_availability: "商家在線狀態",
    category_management: "分類目錄管理",
    product_management: "產品管理",
    giftcard_management: "禮品卡管理",
    coupon_management: "優惠券管理",
    setting_discount: "折扣設置",
    referral_page: "推薦統計/管理",
    product_duplicated: "產品已復制",
    product_duplicated_message: "新產品已創建，新檢查，進行必要的修改後保存。",
    share_qrcode: "二維碼",
    share_qrcode_instant: "二維碼 (即刻結帳)",
    regular_product_qr: "產品標準二維碼",
    instant_co_qr: "即刻結帳二維碼",
    user_input: "用戶輸入",
    ignore_conf_on_checkout: "結帳時無需確認",
    poster: "海報",
    download_poster: "保存海報",
    customers: "客戶列表",
    canada_phone: "加拿大 +1",
    usa_phone: "美國 +1",
    full_name: "全名",
    number_of_orders: "訂單總數",
    points_balance: "積分餘額",
    total_order_amount: "訂單總計",
    last_order_date_range: "最近訂單日期範圍",
    customer_phone: "客戶電話",
    customer_email: "客戶電子郵件",
    total_spent: "總金額",
    total_number_orders: "訂單總數",
    customer_filter: "客戶過濾器",
    send_message: "發送消息",
    view_orders: "查看訂單",
    send_coupon: "發送優惠券",
    view_giftcard: "查看禮品卡",
    send_message_to_customers: "向客戶發送消息",
    send_to: "發送給",
    message_type: "消息類型",
    coupon_promotion: "優惠券促銷",
    store_promotion: "商家促銷",
    product_promotion: "產品促銷",
    body: "內容",
    choose_product: "選擇產品",
    message_sent: "消息發送成功！",
    amt_to: "價格至",
    amt_from: "價格從",
    qty_to: "數量至",
    qty_from: "數量從",
    date_range: "日期範圍",
    select_type: "選擇類型",
    any_date: "任何日期",
    custom_range: "自定義範圍",
    last_order_date_from: "最近訂單日期自",
    last_order_date_to: "最近訂單日期至",
    last_order_date: "最後訂單日期",
    copy_product_url: "拷贝产品链接",
    copy_store_url: "拷貝商家鏈接",
    open_product_webpage: "打開產品網頁",
    admin_user: "管理員用戶",
    no_customers: "沒有客戶",
    see_selected: "查看所選",
    hide_selected: "隱藏所選",
    select_coupon: "選擇優惠券",
    choose_product_desc: "請選擇促銷產品發送給您的客戶。",
    missing_x: "缺少{x}",
    reviews: "評論",
    show_selected: "顯示所選內容",
    count: "數量",
    balance: "餘額",
    reply: "回復",
    user_didnt_leave_rating: "用戶未寫評論，僅留下了評分。",
    anonymous: "匿名",
    send: "發送",
    reply_sent: "成功發送回复！",
    approved: "已批准",
    unapproved: "駁回",
    approve: "已批准",
    unapprove: "駁回",
    review_approval_updated: "評論批准已更新！",
    customer_id: "客戶ID",
    product_id: "產品ID",
    review_date: "評論日期",
    review_update_date: "評論更新日期",
    no_reviews: "無評論",
    search_by_name: "按客戶名稱搜索...",
    dt_start: "開始日期",
    dt_end: "截止日期",
    update_dt_start: "起始更新日期",
    update_dt_end: "截止更新日期",
    error_email_taken: "該電子郵件已用，請使用另一個郵件地址",
    error_username_taken: "該用戶名已用，請使用另一個",
    error_please_try_again: "發生錯誤，請重試",
    local_delivery: "本地配送",
    order_review: "訂單評論",
    change_phone: "更改電話",
    select_option: "選擇選項",
    send_verification_code: "發送驗證碼",
    enter_verification_code: "輸入驗證碼",
    submit_verification_code: "提交驗證碼",
    phone_number_changed: "電話號碼更新成功！",
    phone_number_required: "電話號碼必填",
    phone_number_length_invalid: "電話號碼長度必須在{minlen}到{maxlen}位之間",
    add_item: "添加產品",
    edit_item: "編輯產品",
    website_url: "桌面Web版的域名",
    mobile_url: "移动Web版的域名",
    website_information: "域名",
    download_QR: "Download QR",
    error_message_product: '保存此產品時發生以下錯誤: "{message}"',
    use_category_time_setting: "使用分類目錄上的時間設置",
    store_url_copied: "成功複製商家鏈接！",
    verification_code_sent: "驗證碼已成功發送！",
    password_changed: "密碼更新成功！",
    phone_not_registered: "發生錯誤，該電話號碼尚未註冊",
    vc_expired: "發生錯誤，驗證碼已過期",
    too_many_vc_requests: "發生錯誤，驗證碼請求次數太多",
    customer_aready_exists: "發生錯誤，該客戶已存在",
    an_error_occurred: "發生錯誤，請重試。",
    third_party_courier_restaurant_error_message:
        "更改商舖類型失敗。在將商舖類型更改為餐廳之前，請先在設置 -> 自取/配送中更新配送為非“第三方快遞。",
    no_items: "沒有產品",
    no_active_users: "沒有活躍用戶",
    min_placeholder: "最小",
    max_placeholder: "最大",
    table_rate: "階梯式郵費",
    flat_rate: "平價郵費",
    ups: "UPS",
    fedex: "Fedex",
    c2c: "國到國",
    Dashboard: "控制面板",
    third_party_local_delivery_service: "第三方本地配送服務",
    admin_profile: "管理員個人資料",
    admin_user_delete_error: "刪除管理員用戶時發生錯誤",
    admin_user_delete_perm_error: "您沒有刪除此管理員用戶所必需的權限。",
    ensure_password_six: "密碼必須至少有6個字符長",
    admin_already_exists: "您不能使用此電話號碼，因為另一個用戶已經在使用此號碼。",
    referral_settings: "推薦設置",
    referral_settings_description:
        "推薦計劃是一種增長型營銷策略，旨在鼓勵現有客戶向他們的朋友，家人和同事推薦您的商店或產品。",
    allow_cust_refs: "允許客戶推薦",
    allow_cust_refs_desc:
        "客戶推薦會在被推薦人完成首次註冊或登錄網站後自動加到推薦人的名下；推薦人將從被推薦人完成的每個訂單中獲得獎勵積分。這是一個長期獎勵規則。",
    allow_order_refs: "允許推薦產品或商家",
    allow_order_refs_desc:
        "推薦人將產品或商家網站推薦給被推薦人後，推薦人可在被推薦人完成訂單後獲得獎勵積分。一次性獎勵",
    batch_process_referral_rewards: "以批處理方式在一天結束時計算推薦獎勵積分",
    batch_process_referral_rewards_desc:
        "打開批處理後，僅在一天的結束時，由系統的批處理模塊計算推薦獎勵積分並將其分配給每個推薦人；當關閉批處理模式後，推薦人將在訂單完成後馬上獲得推薦獎勵積分。批處理模式可減少因為發生退單/退款引起的回撤積分的問題。",
    ref_expiry_days: "推薦有效期限",
    ref_expiry_days_desc: "推薦代碼在被推薦人設備上保留的天數。",
    cust_val_points: "客戶推薦獎勵積分比率",
    cust_val_points_desc:
        "推薦客戶可獲得的積分比例。例如，如果此值為10，則推介的客戶每花$1，推薦人將獲得10個獎勵積分。",
    ord_val_points: "推薦產品/網站的獎勵積分比例",
    ord_val_points_desc:
        "推薦產品或者網站可獲得的積分比例。例如，如果此值為10，則推介的客戶每花$1，推薦人將獲得10個獎勵積分。",
    auto_generate_referral_code: "自動生成推薦代碼",
    auto_generate_referral_code_desc: "打開開關即可啟用為每個新註冊的用戶自動生成推薦代碼。",
    click_open_product_page: "點擊打開產品網頁",
    generate_referral_code: "生成代碼",
    referral_code: "推薦碼",
    referral_code_enabled: "推薦代碼已成功啟用！",
    referral_code_disabled: "推薦代碼已成功禁用！",
    referral_code_generated: "推薦代碼已成功生成！",
    referral_code_generated_error: "生成推薦代碼時出錯，請重試",
    referral_code_deleted: "推薦代碼已成功刪除！",
    referral_code_cannot_delete: "您不能刪除此推薦代碼，因為尚未兌換所有獎勵積分。",
    pid: "產品ID",
    "24_hours": "24小時",
    bulk_actions: "批量操作",
    notifications_settings_changed: "通知設置已成功更新！",
    x_field_not_long_enough: " {x}不夠長",
    please_ensure_field_x_long: "請確保它的長度為{x}個字符",
    copy_referral_code: "複製引薦代碼",
    referral_code_copied: "成功複製了推薦代碼！",
    generate_referrals: "生成推薦",
    enable_referrals: "啟用推薦",
    disable_referrals: "禁用推薦s",
    delete_referrals: "刪除推薦",
    live_mode: "生產服務器模式",
    auto_create_delivery_task: "根據新訂單自動創建配送任務",
    service_provider: "服務提供商",
    test_connection: "測試連接",
    delivery_connection_success: "您的第三方本地配送服務連接成功！",
    delivery_connection_failure: "抱歉，您的第三方本地配送服務連接失敗",
    third_party_delivery_settings: "第三方本地配送服務設置",
    need_atleast_address_for_multiple: '您需要至少有一個提貨點才能啟用"多個提貨點"功能。',
    need_atleast_address_for_multiple_delivery: "您需要至少有一個配送區域才能啟用“基於地區的固定配送費“功能。",
    ship_to: "配送國家",
    all_countries: "所有國家",
    specific_countries: "指定的國家",
    allowed_countries: "允許的國家",
    handling_fee: "手續費",
    region: "區域",
    postal: "郵政編碼",
    no_rules: "沒有規則",
    add_table_rate_rule: "添加平價配送規則",
    base_price: "基本價格",
    per_order: "每件訂單",
    per_item: "每件產品",
    handle_type: "處理類型",
    deleted_successfully: "成功刪除",
    enter_address_to_autofill: "這裡輸入地址可自動填寫地址",
    calc_handling_fee: "計算手續費",
    percentage: "百分比",
    dollar: "金額",
    fixed: "固定",
    delete_rule: "刪除規則",
    edit_rule: "編輯規則",
    shipping_settings: "配送設置",
    regular_shipping: "普通配送",
    express_shipping: "快速配送",
    min_shipping_days: "最短配送天數",
    max_shipping_days: "最長配送天數",
    user_id: "用戶ID",
    access_key: "訪問秘鑰",
    container: "容器",
    pickup_method: "取貨方式",
    apply_handling: "處理應用",
    weight_unit: "重量單位",
    max_weight: "最大重量",
    min_weight: "最小重量",
    destination_type: "地址種類",
    package_request_type: "包裹要求種類",
    allowed_methods: "允許的方法",
    customer_packaging: "客戶自包裝",
    ups_letter_envelope: " UPS信件信封",
    customer_supplied_package: "客戶提供的包裹",
    ups_tube: "UPS Tube",
    pak: "PAK",
    ups_express_box: "UPS Express Box",
    ups_worldwide_25: "UPS Worldwide 25 Kilo",
    ups_worldwide_10: "UPS Worldwide 10 Kilo",
    pallet: "Pallet",
    small_exp_box: "Small Express Box",
    medium_exp_box: "Medium Express Box",
    large_exp_box: "Large Express Box",
    regular_daily_pickup: "Regular Daily Pickup",
    on_call_air: "On Call Air",
    one_time_pickup: "One Time Pickup",
    letter_center: "Letter Center",
    customer_counter: "Customer Counter",
    ups_express: "UPS Express",
    ups_expedited: "UPS Expedited",
    ups_worldwide_express: "UPS Worldwide Express",
    ups_worldwide_expedited: "UPS Worldwide Expedited",
    ups_standard: "UPS Standard",
    ups_three_day_select: "UPS Three-Day Select",
    ups_early_am: "UPS Express Early A.M.",
    ups_saver: "UPS Saver",
    divide_equal: "Divide to Equal Weight (one request)",
    origin_weight: "原始重量",
    account_key: "賬號",
    packaging: "打包",
    dropoff: "送貨",
    is_residential_delivery: "住宅交貨",
    meter_number: "米數",
    fedex_envelope: "Fedex Envelope",
    fedex_pak: "Fedex Pak",
    fedex_box: "Fedex Box",
    fedex_tube: "Fedex Tube",
    fedex_10_box: "Fedex 10kg Box",
    fedex_25_box: "Fedex 25kg Box",
    your_packaging: "Your Packaging",
    regular_pickup: "Regular Pickup",
    request_courier: "Request Courier",
    drop_box: "Drop Box",
    business_service_center: "Business Service Center",
    europe_national_priority: "Europe First Priority",
    fedex_1_freight: "1 Day Freight",
    fedex_2_freight: "2 Day Freight",
    fedex_2_day: "2 Day",
    fedex_2_day_am: "2 Day AM",
    fedex_3_day_freight: "3 Day Freight",
    fedex_express_saver: "Express Saver",
    fedex_ground: "Ground",
    first_overnight: "First Overnight",
    ground_home_delivery: "Home Delivery",
    international_economy: "International Economy",
    international_economy_freight: "Inernational Economy Freight",
    international_first: "International First",
    international_ground: "International Ground",
    international_priority: "International Priority",
    international_priority_freight: "International Priority Freight",
    priority_overnight: "Priority Overnight",
    smart_post: "Smart Post",
    standard_overnight: "Standard Overnight",
    fedex_freight: "Freight",
    fedex_national_freight: "National Freight",
    add_c2c_regular_rule: "添加普通跨國配送規則",
    edit_c2c_regular_rule: "編輯普通跨國配送規則",
    add_c2c_express_rule: "添加快捷跨國配送規則",
    edit_c2c_express_rule: "編輯快捷跨國配送規則",
    update_rule: "更改規則",
    united_states: "美國",
    canada: "加拿大",
    united_kingdom: "英國",
    china: "中國",
    edit_table_rate_rule: "編輯階梯配送費率規則",
    require_utensils: "餐具選項",
    require_utensil_tip: "此選項設置決定您的客戶是否可以在結帳頁面上看到“需要餐具選項”。",
    print_request_utensil: "收據上打印餐具的選項",
    print_request_utensil_description: "此選項決定餐具要求是否將顯示在打印的收據上。",
    do_not_show_option: "不顯示這個選項",
    show_utensil_option_not_required: "顯示這個選項，默認為不需要餐具",
    show_utensil_option_required: "顯示這個選項，默認為需要餐具",
    do_not_print: "不打印",
    always_print: "一直打印",
    only_print_utensil_request: "僅在客戶需要餐具時打印",
    only_print_utensil_no_request: "僅在客戶不需要餐具時打印",
    all_referrals_already_generated: "所有選定的客戶已經生成了推薦碼！",
    all_referrals_were_already_enabled: "所有選定客戶的推薦碼已啟用！",
    all_referrals_were_already_disabled: "所有選定客戶的推薦碼已被禁用！",
    km: "公里",
    miles: "英里",
    distance_unit: "距離單位",
    extra_dollar_per_mile: "超過部分每英里的費用",
    payment_option_disabled_unless_required:
        "如果您離開此頁面，此支付選項將被自動禁用。要保持啟用狀態，請填寫所有必填字段。",
    instore_discount: "店內折扣",
    create_automatic_instore_discount: "創建店內自動折扣規則",
    checkout_settings: "離線支付/匿名下單",
    checkout_settings_description:
        "訪客結帳允許客戶在不提供客戶信息的情況下下訂單。通常用於店內自助下單或者掃碼支付方式(虛擬POS終端支付)。",
    guest_checkout: "匿名下單",
    offline_payment: "離線支付",
    block_online_payment: "禁用在線支付",
    instant_pay: "數字資產(禮品卡等)",
    no_menus: "沒有定義菜單",
    enter_address_here: "請輸入地址",
    cut_off_time: "截止時間",
    braintree_allow_paypal: "支持PayPal支付",
    with_min_purchase: "最低購買金額",
    performance: "使用次數",
    format_qr_codes: "選擇二維碼風格",
    format_qr_codes_description: "Format QR Codes as you would like them to appear.",
    scan_and_order: "Scan to Order",
    powered_by: "Powered by",
    download_pdf: "下載PDF",
    confirm_format: "繼續",
    require_scan_instore: "需要掃碼才能允許店內下單",
    qr_code_style: "二維碼風格",
    qr_code_type: "二維碼類型",
    order_online_earn_points: "在線訂購賺取積分",
    a3: "A3",
    a4: "A4",
    squares: "方塊",
    dots: "圓點",
    legal: "Legal",
    background_colour: "背景顏色",
    paper_size: "打印紙大小",
    show_rewards_info: "顯示積分提示",
    bleeding_margin: "打印邊距(毫米)",
    add_margin_between_qrs: "增加二維碼之間的間距",
    add_custom_text: "包括自定義標籤",
    table_numberz: "桌號",
    parking_lot: "停車位號",
    kiosk_number: "自助終端號碼",
    reward_points: "獎勵積分",
    reward_points_extra_customer: "您的帳戶上的積分餘額將在訂單完成後更新。",
    reward_points_extra_admin: "客戶帳戶上的積分餘額將在訂單完成後更新。",
    default_cc_gateway: "默認信用卡處理網關",
    offline_gateway: "離線處理信用卡",
    paypal: "Paypal",
    stripe: "Stripe",
    not_publicly_available: "Not available to public",
    kgs: "公斤",
    kg: "公斤",
    lbs: "磅",
    lb: "磅",
    oz: "盎司",
    grams: "斤",
    support: "支持",
    generate_qr_setting: "二維碼生成器工具",
    generate_qr_setting_description: "製作店鋪海報, 或生產您自己的任意二維碼",
    qr_code_type_description: "選擇二維碼風格",
    store_poster: "海報樣式",
    arbitrary_qr_code: "自定義樣式",
    variables_title: "自定義標籤",
    discount_value: "折扣數值",
    order_types_label: "點餐類型",
    bottom_label: "底部標籤",
    style_settings: "樣式設置",
    url_label: "鏈接地址",
    payment_icons: "付款圖標",
    qr_store_logo: "PNG 格式商家徽標",
    residential: "住宅",
    commercial: "商業",
    "customer_new": "新客戶",
    "customer_returning": "老客戶",
    "abandoned_carts": "未完成的購物車",
    "add_customer": "添加客戶",
    "new_customer": "新客戶",
    "customer_info": "客戶信息",
    "subscribe_message": "客戶同意接收營銷郵件",
    "note": "備註",
    "note_place_holder": "為此客戶添加備註",
    "tags": "標籤",
    "map_search_placeholder": "輸入街道地址",
    "company": "公司",
    "please_input_valid_x": "請輸入有效的{x}",
    "customer_since": "自 {time} 註冊的客戶",
    "customer_note": "客戶備註",
    "no_note_added": "還沒有備註",
    "last_order": "最近訂單",
    "recent_orders": "最近訂單",
    "default_address": "默認地址",
    "edit_customer_info": "編輯客戶信息",
    "update_customer_success": "更改客戶信息成功",
    "edit_address": "編輯地址",
    "default": "默認",
    "set_as_default": "設為默認",
    "modify": "修改",
    "add_address": "添加地址",
    "email_marketing": "市場營銷郵件",
    "edit_status": "編輯狀態",
    "subscribed": "已訂閱",
    "not_subscribed": "未訂閱",
    "edit_email_marketing_status": "編輯市場營銷郵件訂閱狀態",
    "edit_email_marketing_status_warning": "在訂閱市場營銷電子郵件之前，您應該徵得客戶的同意",
    "subscribed_on_x": "開始訂閱時間 {time}",
    "view_all_tags": "查看所有標籤",
    "add_tag": "添加標籤",
    "reset_password_message": "發送了一個鏈接到客戶的郵箱，供客戶重置密碼。",
    "sent_reset_link": "已發送鏈接",
    "update_password": "修改密碼",
    "password_confirmation_sent": "已向客戶發送確認電子郵件",
    "view_all_records": "查看所有記錄",
    "show_last_3_records": "顯示最近三條記錄",
    "x_gift_card_found": "找到{x}個禮品卡",
    "purchase_date": "下單日期",
    "purchase_date_giftcard": "購買時間",
    "gift_card_code": "禮品卡號碼",
    "see_more_orders": "查看更多訂單",
    "no_default_address_set": "沒有默認地址",
    "no_orders_found": "沒有歷史訂單",
    "customer_email_exists": "提供的電子郵件已被其他客戶使用，請使用其它電子郵件。",
    "customer_phone_exists": "提供的電話號碼已被其他客戶使用，請使用其它號碼。",
    "view_detail": "查看詳細",
    "preferred_delivery_method": "配送服務提供方",
    "self_local_delivery": "商戶自行本地配送",
    "third_party_courier": "第三方快遞(UPS, Fedex, 加拿大郵政等)",
    "third_party_local": "第三方本地配送",
    "shipping_fee": "配送費",
    auto_accept_order_settings: "自動接受訂單設置",
    auto_accept_order_settings_description:
        "如果您希望系統自動接受新訂單而不是手動接受訂單，請開啟自動接受訂單設置。您可以定義自動接受訂單的條件。",
    auto_accept_order_enabled: "自動接受訂單",
    auto_accept_order_type_any: "所有訂單",
    auto_accept_order_type_in_store: "店內訂單",
    auto_accept_order_type_pickup: "自取訂單",
    auto_accept_order_type_delivery: "配送訂單",
    auto_accept_order_type_instant_pay: "掃碼支付/其他",
    auto_accept_time_any_time: "任何時間",
    auto_accept_time_store_hours_only: "營業時間內",
    auto_accept_order_time: "下單時間範圍",
    auto_accept_time_setting_any: "任何時間",
    ASAP: "ASAP",
    auto_accept_time_setting_asap: "盡快",
    auto_accept_time_setting_same_day: "當天任何時間",
    auto_accept_time_setting_future_day: "未來日期訂單",
    auto_accept_time_setting_specify: "具體時間",
    auto_accept_due_time_setting: "要求時間設置",
    auto_accept_due_time_value: "要求時間",
    auto_accept_due_time_within: "範圍",
    auto_accept_due_time_hours: "小時",
    auto_accept_due_time_minutes: "分鐘",
    auto_accept_due_time_future_day: "未來日期",
    auto_accept_due_time_same_day: "當天",
    auto_accept_order_status: "接受訂單後的默認狀態",
    auto_accept_payment_type: "支付方式",
    auto_accept_payment_any: "所有方式",
    auto_accept_payment_online: "在線支付",
    auto_accept_payment_offline: "離線支付",
    content_pages: "自定義內容頁面",
    content_pages_description: "管理您的在線商店需要的的自定義頁面",
    add_content_page: "添加自定義內容頁面",
    edit_content_page: "編輯自定義內容頁面",
    content: "內容",
    updated_content_page_list_success:
        "The custom content page(s) have been updated successfully! To reflect it on your online store, please do a re-publish.",
    added_content_page_success: "自定義內容頁面創建成功！ 要將其反映在您的在線商店中，請重新發布。",
    updated_content_page_success: "自定義內容頁面更新成功！ 要將其反映在您的在線商店中，請重新發布。",
    deleted_content_page_success: "自定義內容頁面刪除成功！ 要將其反映在您的在線商店中，請重新發布。",
    delete_content_page_unsuccessful: "無法刪除自定義內容頁面。",
    delete_content_page_confirmation: "您確定要刪除此自定義頁面嗎？",
    duplicate_url_key: "此URL 已在另一個頁面上使用，請更改為其它內容。",
    discounted_upsell: "折扣加售",
    amount_type: "金額類型",
    amount_type_tip: "選擇觸發折扣加售的訂單金額類型",
    discounted_upsell_items: "折扣加售產品",
    add_discounted_upsell_rule: "添加折扣加售規則",
    edit_discounted_upsell_rule: "編輯折扣加售規則",
    delete_rule_confirmation: "您確定要刪除這個規則嗎？",
    grand_total: "訂單總額",
    net_amount: "商品稅前金額",
    reward_points_settings: "獎勵積分設置",
    reward_points_settings_description:
        "設置客戶您的商舖中賺取和使用獎勵積分的方式。要為您的商店啟用獎勵積分，請致電 (778)379-7918 聯繫 Goopter。",
    min_points_redemption_amount: "最低可提取積分的等值金額",
    min_points_redemption_amount_tip:
        "提取積分所需要的最低積分餘額的金額。比如，設置為5時，客戶需要至少等值$5的積分才能提取積分。",
    max_points_redemption_setting: "最高積分提取設置",
    max_points_redemption_type: "限制類型",
    max_points_redemption_type_tip: "用於確定客戶在每個訂單中可提取積分的最大值。",
    max_points_redemption_per_order: "每個訂單的最大可提取積分百分比/最大可用積分支付的金額",
    max_redemption_percentage_per_order: "每個訂單的最大可提取積分百分比",
    max_redemption_amount_per_order: "每個訂單最大可用積分支付的金額",
    max_redemption_percentage_per_order_tip:
        "每個訂單允許客戶可兌換積分的最大百分比。比如設置為10時，客戶最多只能用積分兌換其訂單金額的10%。",
    max_redemption_amount_per_order_tip:
        "每個訂單允許客戶提取積分的最大值。比如當設置為10時，客戶每筆訂單最多可用積分支付$10。",
    no_limit: "沒有限制",
    percentage_of_order: "訂單百分比",
    dollar_value: "金額",
    max_redemption_warn: "*每個訂單允許客戶提取積分的最大值必須大於提取積分所需要的最低積分餘額的金額。",
    orders: "訂單",
    order_id: "訂單編號",
    order_list: "訂單",
    ord_dt: "下單時間",
    exp_dt: "期待時間",
    ord_total: "金額",
    s_method: "訂單類型",
    p_method: "支付方式",
    table_number_no_pound_sign: "桌號",
    start_processing_message: "您確認要確認訂單嗎？",
    mark_as_complete_message: "您確定要把訂單標註為完成嗎？",
    cancel_order_message: "您確定要取消訂單嗎？",
    accept: "接單",
    complete: "完成",
    reject: "拒絕",
    refund: "退款",
    print: "打印",
    quick_print: "快速打印",
    //refund
    refund_amount: "退款金額",
    refund_all: "全額退款",
    refund_remark: "備註",
    //dialog
    "edit-shipping-fee-message": "更改運費將導致取消原訂單並用修改後的運費創建新訂單, 您確定要繼續嗎？",
    edit_shipping_info: "編輯配送地址",
    additional_info: "額外信息",
    party_size: "就餐人數",
    exp_time: "要求配送時間",
    estimatedDeliTime: "預計配送時間",
    delivery_time: "送達時間",
    pickup_time: "取單時間",
    "create-new-order-message": "修改訂單將會取消此訂單，並且創建一個新的訂單，您確定要修改嗎？",
    order_and_account_information: "訂單與客戶信息",
    address_info: "地址信息",
    billing_address: "帳單地址",
    shipping_address: "配送地址",
    delivery_address: "配送地址",
    pickup_location: "自取地址",
    payment_and_shipping_method: "付款與送貨方式",
    payment_info: "支付信息",
    shipping_handling_info: "配送信息",
    item_order: "訂單商品",
    raw_total: "小計",
    notes_for_this_order: "訂單註釋",
    alert_on_notification: "發消息時警告",
    sub_comment: "提交評論",
    modify_discount: "修改折扣",
    add_discount: "添加折扣",
    modify_tips: "修改小費",
    add_tips: "添加小費",
    add_comment: "添加備註",
    modify_shipping_fee: "修改配送費",
    edit_payment: "更改支付",
    edit_extra: "修改信息",
    edit_payment_method: "更改支付方式",
    update_payment_method: "Update Payment Method",
    "select_receipt(s)_to_print": "選擇要打印的收據",
    change_payment_to: "您確定要將付款方式更改為",
    //filter
    order_filter: "訂單篩選條件",
    search_order_with: "搜索訂單...",
    reject_order: "拒絕訂單",
    estimated_ready_time: "預計就緒時間",
    estimated_delivery_time: "預計送達時間",
    enter_reason_for_rejection: "輸入拒絕原因",
    ETA: "預計就緒",
    estimated_delivery_short: "预计送达时间",
    req_time_short: "要求时间",
    exp_time_stort: "預計就緒",
    surcharge: "附加費",
    store_surcharge: "附加費",
    order_surcharge: "訂單附加費",
    not_paid: "未付款",
    authorized: "预授权",
    unpaid: "未支付",
    modify_surcharge: "修改附加費",
    add_surcharge: "添加附加費",
    remove_surcharge: "删除附加费",
    remove_surcharge_confirm_message: "您確認要刪除這個訂單的附加費嗎",
    remove_tips: "删除小費",
    remove_tips_confirm_message: "您確認要刪除這個訂單的小費嗎",
    go_to_live_order: "查看未完成訂單",
    pending_order_warning: "以下訂單尚未接單，",
    pending_print_order_warning: "以下訂單尚未打印，",
    handle_asap: "請盡快處理。",
    order_reminder: "訂單提醒",
    comment_order_pending: "新訂單",
    comment_order_processing: "订单标记为处理中",
    comment_order_complete: "訂單完成",
    comment_order_canceled: "訂單取消",
    comment_order_closed: "訂單關閉",
    comment_order_accepted: "商家已接單",
    comment_order_being_prepared: "商家準備中",
    comment_order_ready_for_pickup: "訂單已經準備好自提",
    comment_order_ready_for_driver: "訂單已經準備好配送",
    comment_order_out_for_delivery: "正在配送",
    comment_order_served: "已上菜",
    update_item: "更改產品",
    update_original_item: "更改產品",
    out_of_stock_replace: "替換產品",
    out_of_stock_remove: "移除產品",
    reverse_current_change: "撤回修改",
    customer_request: "客戶要求",
    out_of_stock_today: "今日售磬",
    out_of_stock_indefinitely: "永久下架",
    choose_a_reason: "更改的原因：",
    choose_an_action: "請選擇一個操作",
    choose_an_item: "選擇一個產品",
    update_an_item: "更改產品",
    replace_an_item: "替換產品",
    pick_one: "必選1項",
    pick_amount: "Required, pick {min}",
    pick_range: " 選擇 {min}-{max}",
    pick_range_up_to: "可選{max}項",
    pick_range_required: "必選{min}-{max}項 ",
    pick_optional: "可選",
    apply_changes: "最終確認",
    suggest_changes: "發送給客戶",
    add_new_item: "增加產品",
    reverse_all_changes: "取消更改",
    remove_x: "移除了產品",
    replace_x_with_y: "更改了產品",
    update_x: "更改了產品",
    add_x: "增加了產品",
    are_you_sure_reverse_all: "取消更改後將返回到原始訂單",
    are_you_sure_send_to_customer: "系統將把訂單更改發送給客戶。請等待客戶確認後再處理訂單。",
    are_you_sure_finalize: "您要確定對訂單的最終更改嗎？在進行此操作之前，請確保已獲得客戶同意。",
    confirm_cancel_changes: "確認取消更改",
    are_you_sure_reject_all: "您確認要取消此訂單嗎？",
    continue: "繼續",
    no_item: "還沒有選擇任何商品",
    no_options_available: "沒有可用的選項",
    confirm_ready_for_pick_up: "您確定要標記為“自取就緒”嗎？",
    confirm_ready_for_delivery: "您确定要标记为“配送就緒“吗？",
    confirm_start_delivery: "您確認要更改狀態嗎？",
    confirm_start_preparing: "您確認要開始準備這個訂單嗎？",
    "confirm_merge": "合併訂單將創建一個新訂單，並將所有未付款訂單標記為取消，已付款訂單標記為完成。您確定要繼續嗎？",
    out_of_stock_warning_message: "您選擇的產品無貨，請在選擇產品之前更改其可用性設置。",
    pending_for_customer_confirmation: "等候客戶確認",
    order_picked_up: "訂單已取",
    start_delivery: "開始配送",
    start_preparing: "開始準備",
    order_start_preparing: "開始準備訂單",
    start_preparing_now: "開始準備",
    start_prapring_now_check_box_message: "這是預定的訂單，您可以插入以標記為開始準備。",
    order_canceled: "訂單已取消",
    pending_for_store_confirmation: "待商家接單",
    store_accepted: "商家已接單",
    store_is_preparing: "商家準備中",
    ready_for_pick_up: "自取就緒",
    ready_for_delivery: "配送就緒",
    ready_for_courier_pick_up: "等待配送員取單",
    rejected_by_courier: "已安排配送員",
    courier_assigned: "已安排配送員",
    courier_accepted: "配送員接單",
    courier_heading_to_store: "配送員前往商家",
    courier_arrive_at_the_store: "配送員抵達商家",
    courier_collected_the_package: "配送員已取單",
    courier_heading_to_customer: "配送員前往客戶處",
    courier_arrive_at_customer: "配送員抵達客戶處",
    delivered_order: "配送完成",
    new_comment_for_order: "訂單: %s 添加了新的備註: %s",
    category_all: "全部",
    category_new: "新訂單 / 待接單",
    category_preparing: "準備中",
    category_ready: "已就緒",
    category_delivering: "配送中",
    category_accepted: "已接單",
    category_scheduled: "預定訂單",
    category_completed: "完成",
    category_canceled: "取消/拒絕",
    category_closed: "關閉",
    "category_serve_restaurant": "已上菜",
    "category_serve_others": "已給客戶",
    status_new: "新訂單",
    status_pending: "待接單",
    status_preparing: "準備中",
    status_ready: "已就緒",
    status_delivering: "配送中",
    status_accepted: "已接單",
    status_scheduled: "預定訂單",
    "status_serve_restaurant": "已上菜",
    "status_serve_others": "已給客戶",
    search_order: "按名字或號碼搜索",
    "search_order_live_order": "按名字, 電話或者桌號搜索",
    view_order_history: "查看歷史訂單",
    no_order: "沒有訂單",
    no_selected_order: "没有选中的订单",
    confirm_on_hold: "關閉在線狀態將導致無法在您的網站上下訂單。 你確定要繼續嗎？",
    total_item_count: "{total}件商品",
    over_due_x: "逾期%s",
    delivery_location: "區域",
    all_products: "所有產品",
    sold_out_only: "售磬產品",
    available_only: "有效產品",
    advanced_mode: "Advanced Mode",
    assigning_items: "Assigning Items",
    are_you_sure_delete_option: "確定要刪除此選項嗎？",
    are_you_sure_delete_product: "你確認想刪除這個產品嗎?",
    are_you_sure_delete_image: "確定要刪除此圖像嗎？",
    are_you_sure_delete_store: "Are you sure you want to delete this store?",
    delete_related_product_warning_message:
        "本商品已設置為以下商品的相關產品。刪除此商品會將其從包含它的相關產品列表中刪除。{item_list}",
    delete_upsell_product_warning_message:
        "本商品已設置為以下商品的加售產品。刪除此商品會將其從包含它的加售產品列表中刪除。{item_list}",
    delete_discounted_upsell_product_warning_message:
        "至少在一個折扣加銷規則中使用了本產品。刪除本產品會將其從所有規則中刪除。",
    confirm_online_status: "警告",
    confirm_online_status_content: "關閉在線狀態後在線下單將會暫停。您確定要繼續嗎?",
    export_orders: "導出訂單",
    update_order_success: "更改訂單成功",
    order_status_updated: "更改訂單狀態",
    update_suggestions_success: "提交建議成功",
    select_x_items: "選擇{x}個產品",
    done_update: "完成更新",
    last_x_days: "過去{x}天",
    custom: "自選",
    clear: "清除",
    item_lan: "產品內容語言",
    export_order_filter_title: "您要導出該訂單？",
    filter_by_x: "按{x}進行過濾",
    select_group_purchase_product: "選擇群接龍",
    sku_prefix: "SKU前綴",
    order_detail: "訂單明細",
    order_detail_per_item: "訂單明細 (每個產品一條記錄)",
    item_detail: "訂單商品詳細信息",
    item_summary: "訂單商品統計信息",
    order_detail_per_order: "訂單明細 (每個訂單一條記錄)",
    all_in_one_report_excel: "Excel 格式的多合一報告",
    export: "導出",
    over_due: "逾期",
    more_actions: "更多操作..",
    more: "更多...",
    no_export_record_found: "未找到可用的導出記錄",
    inaccurate_alert_message:
        "您的更改可能導致統計數字不正確，例如訂單數量及總金額。您可以點擊刷新圖標以獲取準確的數字。",
    refresh: "刷新",
    order_status: "訂單狀態",
    bulk_update: "批量更改",
    order_time: "下單時間",
    share_store: "分享商家",
    show_available_only: "僅顯示可用",
    search_by_item: "按產品搜索",
    require_utensil: "需要餐具",
    no_require_utensil: "無需餐具",
    braintree_payment_error_title: "無法將訂單標記為已完成",
    braintree_payment_error_content: "無法完成此訂單的付款，請檢查訂單備註中的原因。",
    keep_order_open: "保持訂單現有狀態",
    mark_as_complete_and_paid: "將訂單標記為完成並已付款",
    mark_as_closed: "將訂單標記為關閉（如果客戶已通過新的訂單付款）",
    payment_method_change: "更改支付方式",
    cancel_order: "取消訂單",
    bulk_update_none_closable_message: "在更新過程中對話框不可關閉。您可以暫停或停止任務，或者等待所有訂單處理完畢.",
    processing_orders: "批量更新訂單到{status}…",
    resume: "繼續",
    pause: "暫停",
    stop: "停止",
    no_change: "沒有變化",
    task_complete: "任務完成",
    task_canceled: "任務已取消",
    expected_ready_time: "期待就緒時間",
    commute_time: "交通時間",
    esitmated_arrival: "預計送達時間",
    estimated_ready_pickup_time: "預計自取就緒時間",
    estimated_commute_time: "預計交通時間",
    order_ready_time_setting: "訂單準備時間設置",
    min: "最低",
    max: "最多",
    group_by_table: "按桌分組",
    serve_restaurant: "已上菜",
    serve_others: "已給客戶",
    start_serving_message_restaurant: "您確定要將其標記為“已上菜“嗎？",
    start_serving_message_other: "您確定要將其標記為“已給客戶“嗎？",
    merge: "合併",
    group_by_items: "按產品分組",
    payment_transactions: "交易歷史",
    transaction_id_title: "交易號",
    transaction_type_title: "交易類型",
    transaction_time_title: "交易時間",
    create_order: "創建訂單",
    find_or_create_a_customer: "查找或創建客戶",
    create_a_new_customer: "創建新客戶",
    delete_customer: "刪除客戶",
    delete_customer_warning: "您確定要刪除此客戶嗎？",
    no_phone_number: "沒有電話號碼",
    no_email: "沒有電子郵件",
    notes: "備註",
    please_add_some_items: "請添加商品",
    please_choose_a_customer: "請選擇客戶",
    please_input_shipping_method: "請輸入送貨方式",
    click_to_input_shipping_addresss: "點擊輸入配送地址",
    click_to_select_payment_method: "點擊選擇支付方式",
    please_input_valid_credit_card: "請輸入有效的信用卡",
    place_order_with_x: "確認下單 - {x}",
    secure_connection: "信息將通過加密方式安全傳送",
    are_you_sure_remove_gift_card: "你確定要刪除這張禮品卡？",
    set_giftcard: "選擇禮品卡",
    expiry_date: "有效期",
    gift_cards_points_alert: "兌換積分時不能再使用禮品卡; 要使用禮品卡, 請先移除兌換的積分。",
    points_gift_cards_alert_message: "使用禮品卡時就不能再兌換積分; 要兌換積分，請先移除禮品卡。",
    set_points: "選擇積分",
    point_balance: "餘額: {balance}",
    redeem_points_dialog_title: "兌換積分",
    redeem_x: "兌換: {x}",
    please_input_pickup_time: "請選擇自取時間",
    please_input_delivery_time: "請選擇配送時間",
    edit_discount: "Edit Discount",
    remove_discount: "Remove Discount",
    reason: "Reason",
    reason_helper: "Your customer can see this reason",
    invalid_input: "Invalid Input",
    item_oos: "抱歉，以下產品缺貨: {oos_items}。",
    add_items: "Add Items",
    select_category: "選擇目錄",
    "drafts": "臨時訂單",
    cloud_pos: "雲POS",
    "others": "Others",
    "deleted_quotes_successfully": "Quote(s) Deleted Successfully!",
    "confirm_delete_quotes": "Are you sure you want to delete these quotes?",
    "confirm_delete_quotes_content": "Are you sure you want to delete the selected {count} quotes?",
    "click_to_choose_a_customer": "點擊選擇客戶",
    selected_item_s: "已選商品",
    export_select: "導出選中的訂單",
    export_filter: "導出過濾的訂單",
    selected_order_ids: "選中的訂單號",
    select_pickup_location: "請選擇自取位置",
    select_shipping_options_description: "请选择订单类型",
    no_draft_order: "沒有臨時訂單",
    add_gift_card: "添加禮品卡",
    bookvalue: "賬面價值",
    book_value: "賬面價值",
    date_created: "已創建",
    name_slash_phone: "客戶名/電話",
    confirm_delete_product: "確定要刪除此產品嗎？",
    no_gift_cards: "沒有禮品卡。",
    add_edit_gift_card: "添加/編輯禮品卡",
    prepare_order_threshold: "開始準備訂單需要的時間",
    video_desc:
        " 要支持產品視頻，請先將視頻上傳到Youtube或Vimeo。在包含視頻的Youtube或Vimeo上打開網頁，單擊“共享“，複製視頻URL，然後將該URL粘貼到“視頻URL”字段中。",
    giftcard_code: "禮品卡代碼",
    customer_name: "客戶姓名",
    transaction_history: "交易記錄",
    view_transaction_history: "查看交易歷史",
    view_points_history: "查看積分歷史",
    add_credit: "充值",
    transactions: "交易",
    credit: "信用",
    charge: "扣款",
    order: "訂單",
    delta: "變化",
    gift_card_account: "禮品卡賬戶",
    successfully_added_credit: "已成功將$ {value}添加到了禮品卡帳戶。",
    successfully_charged_giftcard: "已成功在禮品卡帳戶中扣除$ {value}。",
    insufficient_balance: "餘額不足",
    insufficient_balance_message: "您要收取的金額高於該帳戶的餘額{maxAmountRounded}.",
    hide_zero_balance_giftcard: "隱藏餘額為零的記錄",
    sales_and_orders: "銷售和訂單",
    view_controls: "視圖設置",
    net_sales: "銷售金額",
    total_sales: "總金額",
    total_orders: "總訂單量",
    average_order: "訂單均價",
    customer_statistics: "客戶統計",
    total_customers: "總客戶數",
    new_customers: "新客戶數",
    ret_customers: "回頭客",
    item_sales: "產品銷售",
    overview: "總覽",
    total_amount: "總金額",
    top_items_by_sale: "最暢銷商品",
    least_items_by_sale: "銷量最少商品",
    quantity: "數量",
    sold: "已售",
    average_order_price: "Average Order Price",
    shipment_tracking_info: "快遞追踪信息",
    carrier: "快遞公司",
    select_carrier: "選擇快遞公司",
    track_number: "運單號碼",
    delete_order_tracking_confirmation: "您確定要刪除此快遞追踪記錄嗎？",
    duplicate_order_tracking_message: "輸入的運單號已存在，請使用其他值重試。",
    notify_customer: "通知客戶",
    add_tracking: "添加運單號",
    add_printer: "添加打印機",
    print_test_page: "打印測試頁",
    receipt_delete_confirm_title: "確認刪除收據",
    //print view
    table_number: "桌號",
    welcome_text1: "感謝您的惠顧",
    welcome_text2: "歡迎在線訂購",
    //Common Setting
    beep_on_new_order: "語音提醒打印",
    alert_on_new_order: "提醒打印",
    auto_print: "自動打印",
    beep_on_print: "打印時語音提示",
    sound_alert_on_printer_disconnect: "打印機斷開時語音報警",
    //individual
    receipt_name: "收據名稱",
    printer_name: "打印機名稱",
    enable_printing: "啟用打印",
    autoPrint: "自動打印",
    receipt_template: "收據模板",
    show_price: "打印價格",
    split_items: "分單",
    with_QR_code: "在底部打印二維碼",
    print_selected_categories_only: "只打印選定的分類",
    add_receipt: "添加收據",
    delete_receipt: "刪除收據",
    please_select_printer: "請選擇打印機",
    receipt_name_missing: "請添加一個收據名稱",
    printer_missing: "請添加打印機",
    primary_lan_missing: "請選擇主語言",
    select_printer: "選擇打印機",
    save_receipt: "保存收據",
    receipt_list: "收據列表",
    "select-template-placeholder": "選擇模板",
    "select-first-language": "選擇第一語言",
    "select-second-language": "選擇第二語言",
    included_products: "除選定目錄外的額外產品",
    included_products_info: "添加需要顯示在收據上的其它產品",
    excluded_products: "排除的產品",
    excluded_products_info: "添加不需要顯示在收據上的產品",
    categories_included: "包括的目錄",
    categories_included_info: "加要顯示在收據上的目錄",
    categories_excluded: "排除的目錄",
    categories_excluded_info: "添加您不想在收据上显示的目录",
    auto_print_on_status: "自動打印的訂單狀態",
    new_not_paid: "新訂單（未付款）",
    new_paid: "新訂單（已付款）",
    order_accepted: "訂單已接受/已付款",
    order_update: "訂單內容已更新",
    order_complete: "處理結束的訂單",
    order_cancel: "訂單被取消",
    missing_auto_print_status: "請選擇自動打印狀態",
    repeat_product: "產品已被選中，請先從之前的選擇中刪除",
    repeat_category: "類別已被選中，請先從之前的選擇中刪除",
    missing_selected_categories_only: "請在“僅打印所選類別”選項中選擇類別或產品",
    total_included_tips: "總價已包含小費",
    surcharge_paid: "附加費(已支付)",
    surcharge_unpaid: "附加費(未支付)",
    surcharege_paid_message: "此订单不包括附加费。请参考订单 #{id}",
    surcharge_paid_to_message: "本訂單是用來支付訂單 #{id}的附加費",
    total_refunded: "退款總額",
    print_time: "打印時間",
    reprint: "重印",
    scan_qr_to_order: "掃碼下單",
    referral: "推薦",
    referrals: "推薦",
    total_referrals: "總推薦數量",
    new_referrals: "新推薦數量",
    new_referral_orders: "新推薦訂單",
    average_referral_orders: "平均推薦訂單金額",
    referrals_count: "推薦數量",
    reward_balance: "積分餘額",
    add_reward_points: "添加積分",
    redeem_reward_points: "扣除積分",
    add_rewards_points_title: "添加積分: {customer_name}",
    redeem_rewards_points_title: "扣除積分: {customer_name}",
    points_history: "積分歷史",
    referral_order_history: "推薦訂單歷史",
    customer_referrals: "推薦的客戶",
    date_time: "日期/時間",
    points_change: "積分變化",
    add_points: "添加積分",
    add_by_points: "按積分",
    add_by_value: "按金額",
    redeem_points: "提取積分",
    redeem: "提取",
    redeem_all: "全部提取",
    view_referrals_list: "查看推薦列表",
    referred_orders_amount: "推薦的訂單",
    earned_points: "積分",
    min_num_ord: "最少單數",
    max_num_ord: "最多單數",
    min_ref_ord_amt: "最低推薦訂單總額",
    max_ref_ord_amt: "最高推薦訂單總額",
    min_points_earned: "最低積分",
    max_points_earned: "最高積分",
    referral_points: "推薦積分",
    min_points_bal: "最低積分",
    max_points_bal: "最高積分",
    min_reward_pts: "最低積分",
    max_reward_pts: "最高積分",
    reward_action_admin: "",
    reward_action_register: "",
    reward_action_newsletter: "",
    reward_action_review: "",
    reward_action_tag: "",
    reward_action_order_extra: "購買積分",
    reward_action_order: "用獎勵積分支付訂單",
    reward_action_invitation_customer: "推薦客戶積分",
    reward_action_invitation_order: "推薦訂單積分",
    reward_action_creditmemo: "商家獎勵積分",
    reward_action_salesrule: "購買訂單積分",
    reward_action_revert: "提取積分",
    reward_action_creditmemo_void: "推薦的訂單退款",
    referral_range: "推薦範圍",
referral_balance_range: "推薦餘額範圍",
referral_order_range: "推薦訂單範圍",
new_store_management: "商店管理",
signup_id: "註冊ID",
admin_id: "管理員ID",
category_id: "類別ID",
rejected: "已拒絕",
need_approval: "需要批准",
    not_found_description: "抱歉！找不到頁面",
    not_found_detail_description: "很抱歉，您要查找的頁面不存在，已被刪除，名稱已更改或暫時不可用",
    back_to_home: "返回首頁",
    enter_table_no: "請輸入桌號",
    enter_party_size: "請輸入人數",
    enter_tableno_party_size: "請輸入桌號和人數",
    number_of_reviews: "查看%s條評價",
    only_have_stock_option: "選項%2$s中的%1$s只有%3$d個庫存。",
    asset_statements: [
        {
            "relation": ["delegate_permission/common.handle_all_urls"],
            "target": { "namespace": "web", "site": "https://m.goopter.com" },
        },
    ],
    payment_device_name: "支付設備名",
    port_number: "端口號",
    port_number_hint: "端口號",
    kiosk_device_name: "自助下單設備名",
    "3rd_party_package_hint": "第 3 方包名",
    order_items: "Order Items",
    add_to_cart_success: "Product has been successfully added to the cart",
    add_back: "Add Back",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    with_option: "With Option(s)",
    qrcode: "QRcode",
    email_required: "Email is required",
    password_required: "Password is required",
    phone_required: "Phone is required",
    vc_required: "Verification code is required",
    please_input_at_least_two_characters: "Please input at least two characters",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    phone_num: "Phone #",
    change_address: "Change Address",
    purchase_a_giftcard: "Purchase a Gift Card",
    self_checkout_disabled: "Quick pay is not available for this store.",
    camera_error_title: "Camera access denied",
    camera_error_description_dine_in: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text: "Enable Camera",
    social_binding: "Social Binding",
    linked: "Linked",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    your_order: "Your Order",
    clover_setting_tip: "Clover (e-commerence)",
    global_payment_setting_tip: "Global payment (Payfields)",
    account_credential: "Account credential",
    global_payment_api_key: "Global payment API key",
    api_secret: "API secret",
    duplicate_name_msg: "There is already a menu with this name. Please use a different one.",
    print_layout_on: "Print layout ON",
    print_layout_off: "Print layout OFF",
    no_admin_user_access: "Save unsuccessful: You do not have the required permissions to perform this action.",
    cloud_solution: "Cloud Solution",
    semi_integrated_solution: "Semi Integrated Solution",
    enable_special_request: "Enable Special Request",
    special_request_tip: "Turn this on to allow users to submit special requests when adding this item to the cart.",
    apiSecret: "Api Secret",
    auth_key: "Auth Key",
    config_code: "Config Code",
    dev_id: "Developer ID",
    key_id: "Key ID",
    signing_secret: "Signing Secret",
    third_party_delivery_error: "一次只能啟用一個第三方配送服務。",
    third_party_field_error_message: "錯誤：您必須填寫所有字段",
    doordash_delivery: "Doordash 配送",
    delivery_request_title: "申請配送服務",
    request_delivery_estimated_fee: "此訂單的預計配送費：{estimatedDeliveryFee} + 稅",
    doordash_delivery_confirmation: "配送請求已發送，將根據配送員位置和您的訂單緊迫性來分配一名配送員。",
    successful_cancellation: "配送申請已被取消，將不收取任何服務費。",
    doordash_cancel_failure:
        "取消配送請求失敗，請致電 855-599-7066 申請取消，並提供您的 DoorDash Drive ID：{delivery_task_id}，客戶姓名：{name}，客戶電話號碼：{customer_phone_number}，以及您的店名。",
    doordash_cancel_failure_note: "請注意，您還需支付原配送費和稅金。",
    cancellation_confirm_title: "確認取消",
    cancel_confirmation_msg: "您確定要取消此訂單的配送請求嗎？",
    estimated_delivery: "預計配送費用/時間",
    estimated_delivery_fee: "{price} + 稅",
    delivery_fee_try_again: "無法獲取運費",
    track_delivery: "跟踪配送位置",
    request_delivery_service: "申請配送服務",
    cancel_delivery: "取消配送",
    estimated_doordash_delivery_time: "送達時間 {time}",
    use_doordash: "使用 Doordash 配送",
    delivery_sent: "配送請求已經發送",
    request_cancelled: "配送請求已取消",
    both_disabled_error: "請啟用其中一個第三方配送服務",
    create_delivery_request_failed: "創建配送任務失敗。請稍後再試。",
    cancel_delivery_request: "取消配送請求",
    cancel_failure_subtitle_one: "請聯繫支持團隊 ",
    cancel_failure_subtitle_two: " 請求取消。",
    cancel_failure_list_title: "請準備好提供以下信息：",
    cancel_failure_doordash_id: "Doordash ID: ",
    cancel_failure_customer_name: "客戶姓名：",
    cancel_failure_customer_phone_number: "客戶電話：",
    cancel_failure_store_name: "店鋪名稱：",
    failed_cancel_delivery_request: "取消配送請求失敗",
    default_item_name: "默認產品名稱",
    default_order_value: "默認訂單金額",
    default_tip_amount: "默認小費金額",
    default_pick_up_instructions: "默認取單說明",
    contains_alcohol: "含有酒精",
    timeslot_delta_title: "時間列表中的每個元素的時間差",
    timeslot_gap_title: "二個相鄰時間列表記錄的時間差",
    timeslot_delta_hints:
        "時間列表中每個元素的時間差。如果值為 0，則時間段列表將是單個時間列表，如 9:00 am; 9:30 am; 10:00 am ...； 如果該值大於 0，那麼它將是一個時間範圍，例如，如果值為 15，則時間段列表格式將類似於 9:00 am - 9:15 am; 9:15 am - 9:30 am  ....",
    timeslot_gap_hints:
        "兩個相鄰時間列表記錄之間的時間差。例如 如果該值設置為 30 並且時間列表中的每個元素的時間差為 0，則時間段記錄格式為: 9:00 am, 9:30 am, 10:00 am ..； 如果時間列表中的每個元素的時間差為 15，則時隙記錄格式將為: 9:00 am - 9:15am; 9:30 am - 9:45 am; 10 am - 10:15 am...",
    default_pick_up_delay_time: "默認取單延遲時間",
    pickup_delay_time_hint:
        "取单延迟时间定义了从现在开始到可以由配送员来取之前的准备时间。例如：如果该值设置为 30，则取单的默认开始时间将为当前时间 + 30 分钟。",
    pre_order_days: "可預定天數",
    create_customer_success: "成功創建客戶",
    delivery_options: "配送選項",
    meet_at_door: "門口見面",
    leave_at_door: "留在門口",
    meet_outside: "在外面見面",
    delivery_instructions: "額外配送指令",
    doordash_should_send_notifications: "通過 SMS 發送配送狀態通知",
    delivery_fee_credit_limit: "配送費透支信用額度",
    delivery_failed_title: "創建配送任務失敗",
    error_enter_allowed_country_field: "您選擇了指定國家的選項，請在保存前輸入允許的國家/地區。",
    insufficient_fund_title: "配送请求的资金不足",
    insufficient_fund_msg:
        "您没有足够的资金来请求DoorDash配送。您可以电子转账至 payment@goopter.com 或付款给Goopter以继续使用DoorDash配送服务。",
    support_number: "服务电话：(778) 379-7919",
    failed_to_create_delivery_message_line1: "创建配送任务失败。",
    failed_to_create_delivery_message_line2: "请修正问题后重试。",
    failed_to_cancel_delivery_message: "取消配送失败，请从以下错误信息中查看原因。",
    failed_to_update_delivery_message: "更新配送任务失败，请从以下错误信息中查看原因。",
    input_order_value: "输入订单金额",
    input_pickup_time: "选择取单时间",
    input_delivery_time: "选择配送时间",
    order_value: "订单价值",
    mandatory_fields_for_delivery_task: "創建配送任務的必填字段",
    review_booster: "客戶評價助推器",
    enable_automated_review_sms: "啟用自動短信評價提醒",
    sms_review_reminder_style: "短信評價提醒風格",
    social_review_url: "發布社交媒體評價的鏈接",
    review_hint: "客戶評價提示信息",
    review_delay_time: "發送自動評價提醒的延遲時間",
    no_duplicate_review_same_day: "每個用戶每天只允許一次評論",
    message_limit_per_month: "每月總短信消息限制",
    review_booster_setting: "客戶評價助推器設置",
    review_booster_setting_description:
        "客戶評價助推器是為了提醒客戶在與商家完成訂單後給予及時的反饋，並鼓勵客戶在社交媒體上發布正面評價。",
    generate_google_review: "生成Google 評分的鏈接",
    // TOADD
    "pickUp.address": "Pick Up Address",
    "dropOff.address": "Drop Off Address",
    "dropOff.time": "Drop Off Time",
    "pickUp.time": "Pick Up Time",
    "additionalInfo.itemsInfo": "Items",
    "Meet outside": "Meet outside",
    pickup_address: "取件地址",
    dropoff_address: "送達地址",
    dropoff_time: "送達時間",
    input_dropoff_time: "選擇送達時間",
    generate_order_id: "生成訂單號",
    electronic_item: "電子產品",
    sub_total: "小計",
    new_option: "添加選項",
    new_item: "添加物品",
    please_select: "請選擇",
    delivery_remark_placeholer: "配送注意事項",
    delivery_option: "送貨選項",
    missing: "遺漏",
    firstname: "名字",
    lastname: "姓氏",
    zipcode: "郵編",
    new_pending: "新訂單",
    driver_info_label: "配送員信息",
    assigning_courier: "安排配送員中",
    confirmed: "確認",
    enroute_to_pickup: "取件途中",
    arrived_at_pickup: "到達取貨點",
    picked_up: "已取件",
    enroute_to_dropoff: "派送途中",
    arrived_at_dropoff: "到達配送目的地",
    status_delivered: "完成配送",
    status_cancelled: "取消",
    cancel_task: "取消任務",
    task_detail: "任務詳情",
    task_items: "配送物品",
    task_delivery_status: "配送狀態",
    pick_up: "自取",
    drop_off: "送達",
    ongoing: "進行中",
    tip_amount: "小費金額",
    order_info: "訂單信息",
    time_info: "時間信息",
    requested_dropoff_time: "要求送達時間",
    requested_pickup_time: "要求取件時間",
    est_pickup_time: "預計取件時間",
    est_dropoff_time: "預計送達時間",
    new_task_btn_label: "新任務",
    csv: "CSV",
    task_details: "Task Details",
    edit_order_id: "編輯訂單編號",
    request_local_delivery: "請求本地配送",
    discard_task: "放棄任務",
    add_an_item: "添加產品",
    add_an_option: "添加選項",
    create_on: "創建時間",
    total_delivery_fee: "運費總額",
    total_balance: "總餘額 $",
    proceed: "繼續",
    TBD: "待定",
    get_delivery_fee_err: "無法計算運費：",
    confim: "確認",
    distance_error_msg: "指定地址超出配送距離，請選擇其他地址。",
    new_address_error_msg: "無法添加新地址，請選擇其他地址後重試。",
    cancel_create_task_msg: "您有未保存的更改，您確定要放棄創建任務嗎？",
    discard_change_msg: "您有未保存的更改，確定要放棄修改嗎？",
    change_pickup_time_warning_msg:
        "您只能為取貨或者送達時間指定其中一個時間。如果您提供送達時間，取貨時間將更改為盡快。您要繼續更改嗎？",
    change_dropoff_time_warning_msg:
        "您只能為取貨或者送達時間指定其中一個時間。如果您提供取件時間，送達時間將更改為盡快。您要繼續更改嗎？",
    remove_dropoff_time_warning_msg:
        "您只能指定取單時間或送達時間。如果您指定送達時間，則將刪除取單時間。您要繼續更改嗎？",
    remove_pickup_time_warning_msg:
        "您只能指定取單時間或送達時間。如果您指定取單時間，則將刪除送達時間。您要繼續更改嗎？",
    unexpected_error_msg: "意外的錯誤",
    tracking_url_not_found: "未找到追踪訂單的鏈接。",
    input: "輸入",
    delivery_fee_total: "運費總額",
    grocery: "百货",
    cake: "蛋糕",
    flower: "花卉",
    document: "文件",
    appliance: "家电",
    furniture: "家具",
    other: "其他",
    contact_info: "聯繫信息",
    search_address_placeholder: "通過電話號碼或姓名搜索地址",
    create_address: "創建地址",
    succeeded_msg: "{msg_type} 成功",
    delivery_cancelled: "已取消訂單: {id}",
    discard_change: "放棄修改",
    new_address: "新地址",
    search_result_msg: "搜索結果 · 找到 {records} 條記錄",
    search_customer: "搜索客戶r",
    input_address: "輸入地址",
    update_address: "更改地址",
    item_option: "商品選項",
    new_item_option: "新商品選項",
    value_or_tips: "訂單價值/小費",
    edit_or_add_address_info: "添加/編輯地址",
    not_authorized: "未授權",
    not_authorized_delivery_msg:
        "您尚未獲得授權使用第三方本地配送的服務，請聯繫Goopter開通本地配送服務，郵箱：support@goopter.com，電話：778-379-7918",
    delivery_task_created: "配送任務已創建, 訂單號 #{order_id}.",
    failed_to_refund: "退款失败",
    addressLineTwo: "單元號/樓層",
    buzzCode: "門鈴",
    componentName: "公司/大樓名稱",
    add_balance: "增加餘額",
    delivery_fee_balance_update: "更改配送服務信用餘額",
    current_balance: "當前信用餘額",
    add_balance_action: "操作",
    add_balance_credit_label: "增加信用餘額",
    add_balance_debit_label: "扣除信用餘額",
    new_balance: "最新餘額", 

    //new modified
    ppcp: "PayPal Complete Payments",
    paypal_complete_payments_paypal_with_3ds: "Credit Card",
    paypal_complete_payments_paypal: "PayPal",
    paypal_complete_payments_google_pay: "Google 支付",
    paypal_complete_payments_apple_pay: "Apple 支付",
    paypal_complete_payments_venmo: "Venmo",
    paypal_complete_payments_paylater: "PayPal (Pay Later)",
    paypal_complete_payments_wallet_credit_card: "Paypal Credit Card",
    merchant_number_error: "商家號碼錯誤",
    merchant_number_error_msg: "商家號碼必須是數字",
    ppcp_account_setting: "PayPal Complete Payments",
    ppcp_mode: "收款模式",
    save_changes_onboard: "登錄 PayPal 帳戶",
    disconnect_paypal_button: "登出帳戶",
    ppcp_account_setting_tip:
        "PayPal Complete Payments 為商家提供最先進、最全面的在線支付支持，可以讓您的 PayPal 商業帳戶與 Goopter 電商系統無縫對接。",
    ppcp_merch_name: "商家名稱",
    ppcp_merch_phone: "聯繫電話",
    ppcp_merchant_id: "商家 ID",
    ppcp_txn_type: "支付操作",
    sale: "銷售",
    partner_fee_section: "商家費用",
    add_partner_fee_rule: "增加商家費用規則",
    ppcp_payment_google_pay: "Google 支付",
    ppcp_payment_apple_pay: "Apple 支付",
    ppcp_payment_paypal: "PayPal",
    important_notice: "重要提示:",
    ensure_you: "請確保您已經遵循",
    important_notice_tips: "以保護您的交易。請注意，寄送到交易確認以外的地址可能會導致您的買家保障失效。",
    Seller_Protection_Policy: "賣家保障政策",
    select_order_type: "選擇訂單類型",
    partner_fee_type: "商家費用類型",
    partner_fee_value: "商家費用比例/金額",
    partner_fee_based_on: "費用計算方式",
    extra_fee_tips: "額外小費",
    extra_fee_delivery: "額外配送費",
    extra_fee_surcharge: "附加費用",
    ppcp_pickup_fee: "自取",
    ppcp_instore_fee: "堂食",
    ppcp_delivery_fee: "配送",
    ppcppercentage: "百分比",
    ppcpfixed: "固定金額",
    fee_type: "商家費用類型",
    fee_value: "商家費用比例/金額",
    additional_fees_includes: "額外費用",
    partner_extra_fee_tips: "小費",
    partner_extra_fee_delivery: "配送費",
    partner_extra_fee_surcharge: "附加費用",
    confirm_delete_partner_fee: "刪除商家費用規則",
    confirm_delete_partner_fee_description: "您確定要刪除商家費用規則嗎？",

    invalid_fee_value: "無效的費用",
    InStore: "堂食",
    enter_valid_number_for_fee_value: "請輸入有效的費用數額",
    payment_data_error: "支付數據錯誤",
    payment_data_error_msg: "請檢查您的支付數據",
    ppcp_partner_fee_type_in_store: "商家費用類型：堂食",
    ppcp_partner_fee_value_in_store: "商家費用比例/金額：堂食",
    ppcp_partner_fee_type_pickup: "商家費用類型：自取",
    ppcp_partner_fee_value_pickup: "商家費用比例/金額：自取",
    ppcp_partner_fee_type_delivery: "商家費用類型：配送",
    ppcp_partner_fee_value_delivery: "商家費用比例/金額：配送",
    select_fee_type: "選擇費用類型",
    enter_fee_value: "輸入費用數額",
    edit_partner_fee_rule: "編輯商家費用規則",
    select_fee_based_on: "選擇費用計算方式",
    partner_fee_details: "商家費用詳情",
    ppcp_partner_fee_type: "商家費用類型",
    ppcp_partner_fee_value: "商家費用比例/金額",
    ppcp_contingency_3d_secure: "3D Secure",
    paypal_complete_payments_credit_card: "信用卡",
    paypal_complete_payments: "信用卡",
    payment_validation_error: "支付驗證錯誤",
    payment_validation_PaymentsReceivable_error: 
        "賬戶已被 PayPal 限制，請檢查 PayPal 賬戶收件箱，查收來自 PayPal 的郵件，以確定下一步操作。",
    payment_validation_PrimaryEmailConfirmed_error:
        "請確認您的 PayPal 賬戶的電子郵件地址。",
    payment_validation_OauthIntegrations_error:
        "請重新進行“開始連接到 PayPal”的操作，並授予我們第三方權限。如果在此註冊流程中出現任何復選框，商戶需要勾選這些復選框。",
    payment_option_error: "請確保只使用了一個付款選項。",
    payment_option_error_content:
        "PayPal Complete Payments 和 BrainTree 支付不能同時使用。",
    fail_to_get_onboard_user_error_message: "獲取用戶信息失敗，請稍後再試。",
    partially_refunded: "部分退款",
    fully_refunded: "已退款",
    voided: "取消",
};
