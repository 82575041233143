import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Select, Button, Switch, Modal, Input, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import aH from "./../../../../admin-users/helper";
import _ from "lodash";
import config from "config";

const currencySymbols: { [key: string]: string } = config.CURRENCY_SYMBOL;

const PPCPModal = ({
    visible,
    editingRule,
    onClose,
}: {
    visible: boolean;
    editingRule: any;
    onClose: () => void;
}): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const partnerFeeRules = useSelector((state: RootState) => state.store?.storePaymentOptions?.ppcp_partner_fee);
    const currUser = useSelector((state: RootState) => state.user);
    const storeCurrency =
        useSelector((state: RootState) => state.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const [feeRules, setFeeRules] = useState<any[]>([]);
    const [orderType, setOrderType] = useState<string | null>(null);
    const [feeType, setFeeType] = useState<string>("percentage");
    const [feeValue, setFeeValue] = useState<number>(0);
    const [feeBasedOn, setFeeBasedOn] = useState<string>("net_amount");
    const [extraFees, setExtraFees] = useState<{ tips: boolean; deliveryFee: boolean; surcharge: boolean }>({
        tips: false,
        deliveryFee: false,
        surcharge: false,
    });

    const checkPermissions = () => {
        return aH.isSuperManagerUser(currUser);
        // return true;
    };

    const getCheck = (title: string, value: any) => (
        <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
            <div className="payment-subtitle">
                <FormattedMessage id={title} />
            </div>
            <Input type={"text"} className="custom-input-textfield halfish-size-input" value={value} readOnly />
            <FormattedMessage id="cannot_access" />
        </div>
    );

    const allOrderTypes = ["Delivery", "InStore", "Pickup"];
    const availableOrderTypes = allOrderTypes.filter((type) => !feeRules.some((rule) => rule.orderType === type));

    useEffect(() => {
        if (Array.isArray(partnerFeeRules)) {
            setFeeRules(partnerFeeRules);
        }
        if (editingRule) {
            setOrderType(editingRule.orderType);
            setFeeType(editingRule.feeType);
            setFeeValue(editingRule.feeValue);
            setFeeBasedOn(editingRule.feeBasedOn);
            setExtraFees(editingRule.extraFees);
        } else {
            resetForm();
        }
    }, [partnerFeeRules, editingRule, visible]);
    const resetForm = () => {
        setOrderType(null);
        setFeeType("percentage");
        setFeeValue(0);
        setFeeBasedOn("net_amount");
        setExtraFees({ tips: false, deliveryFee: false, surcharge: false });
    };
    const saveFeeSettings = () => {
        if (orderType) {
            const newRule = {
                orderType,
                feeType,
                feeValue,
                feeBasedOn,
                extraFees,
            };
            if (_.isNil(feeValue) || isNaN(feeValue) || feeValue < 0 || feeValue > 100) {
                Modal.error({
                    title: intl.formatMessage({ id: "invalid_fee_value" }),
                    content: intl.formatMessage({ id: "enter_valid_number_for_fee_value" }),
                });
                return;
            }
            let rules;
            if (editingRule) {
                const updatedRules = feeRules.map((rule) =>
                    rule.orderType === editingRule.orderType ? newRule : rule
                );
                setFeeRules(updatedRules);
                rules = updatedRules;
                // dispatch(updateFeeRule(newRule));
            } else {
                setFeeRules([...feeRules, newRule]);
                rules = [...feeRules, newRule];
                // dispatch(addFeeRule(newRule));
            }
            dispatch(setPaymentState({ name: "ppcp_partner_fee", value: rules }));
            onClose();
        } else {
            Modal.error({
                title: intl.formatMessage({ id: "missing_fields" }),
                content: intl.formatMessage({ id: "please_fill_all_fields" }),
            });
        }
    };

    const renderOrderTypeSelect = () => {
        if (!checkPermissions()) {
            return getCheck("order_type", orderType);
        }

        return (
            <React.Fragment>
                <div className="options-sub-header mt-1">
                    <FormattedMessage id="order_type" />
                </div>
                <Select
                    value={orderType}
                    className="multi-menu-input d-flex align-items-center"
                    placeholder={intl.formatMessage({ id: "select_order_type" })}
                    onChange={(value) => setOrderType(value)}
                >
                    {availableOrderTypes.map((type) => (
                        <Select.Option key={type} value={type}>
                            <FormattedMessage id={type} />
                        </Select.Option>
                    ))}
                </Select>
            </React.Fragment>
        );
    };

    const renderFeeTypeSelect = () => {
        if (!checkPermissions()) {
            return getCheck("partner_fee_type", feeType);
        }

        return (
            <React.Fragment>
                <div className="options-sub-header mt-3">
                    <FormattedMessage id="partner_fee_type" />
                </div>
                <Select
                    value={feeType}
                    className="multi-menu-input d-flex align-items-center"
                    placeholder={intl.formatMessage({ id: "select_fee_type" })}
                    onChange={(value) => setFeeType(value)}
                >
                    <Select.Option value="percentage">
                        <FormattedMessage id="percentage" />
                    </Select.Option>
                    <Select.Option value="fixed">
                        <FormattedMessage id="fixed" />
                    </Select.Option>
                </Select>
            </React.Fragment>
        );
    };

    const renderFeeValueInput = () => {
        if (!checkPermissions()) {
            return getCheck("partner_fee_value", feeValue);
        }

        return (
            <div className="d-flex flex-column w100 mt-3">
                <div className="options-sub-header">
                    <FormattedMessage id="partner_fee_value" />
                </div>
                <InputNumber
                    type="number"
                    value={feeValue}
                    className="name-input-field"
                    placeholder={intl.formatMessage({ id: "enter_fee_value" })}
                    prefix={feeType === "fixed" ? currencySymbols[storeCurrency] : "%"}
                    onChange={(value) => {
                        setFeeValue(value);
                    }}
                />
            </div>
        );
    };

    const renderFeeBasedOnSelect = () => {
        if (!checkPermissions()) {
            return getCheck("partner_fee_based_on", feeBasedOn);
        }

        return (
            <React.Fragment>
                <div className="options-sub-header mt-3">
                    <FormattedMessage id="partner_fee_based_on" />
                </div>
                <Select
                    value={feeBasedOn}
                    className="multi-menu-input d-flex align-items-center "
                    placeholder={intl.formatMessage({ id: "select_fee_based_on" })}
                    onChange={(value) => setFeeBasedOn(value)}
                >
                    <Select.Option value="net_amount">
                        <FormattedMessage id="net_amount" />
                    </Select.Option>
                    <Select.Option value="order_total">
                        <FormattedMessage id="order_total" />
                    </Select.Option>
                </Select>
            </React.Fragment>
        );
    };

    const renderExtraFeesSwitches = () => {
        if (!checkPermissions()) {
            return (
                <React.Fragment>
                    <div className="options-sub-header mt-3">
                        <FormattedMessage id="additional_fees_includes" />
                    </div>
                    <>
                        {getCheck("extra_fee_tips", extraFees.tips ? "Enabled" : "Disabled")}
                        {getCheck("extra_fee_delivery", extraFees.deliveryFee ? "Enabled" : "Disabled")}
                        {getCheck("extra_fee_surcharge", extraFees.surcharge ? "Enabled" : "Disabled")}
                    </>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="options-sub-header mt-3">
                    <FormattedMessage id="additional_fees_includes" />
                </div>
                <div className="switch-container d-flex align-items-center flex-wrap w100">
                    <div className="switch-box d-flex align-items-center mr-4 justify-content-start">
                        <div className="options-sub-header mr-2">
                            <FormattedMessage id="extra_fee_tips" />
                        </div>
                        <Switch
                            checked={extraFees.tips}
                            onChange={() => setExtraFees((prev) => ({ ...prev, tips: !prev.tips }))}
                        />
                    </div>
                    <div className="switch-box d-flex align-items-center mr-4 justify-content-start">
                        <div className="options-sub-header mr-2">
                            <FormattedMessage id="extra_fee_delivery" />
                        </div>
                        <Switch
                            checked={extraFees.deliveryFee}
                            onChange={() => setExtraFees((prev) => ({ ...prev, deliveryFee: !prev.deliveryFee }))}
                        />
                    </div>
                    <div className="switch-box d-flex align-items-center justify-content-start">
                        <div className="options-sub-header mr-2">
                            <FormattedMessage id="extra_fee_surcharge" />
                        </div>
                        <Switch
                            checked={extraFees.surcharge}
                            onChange={() => setExtraFees((prev) => ({ ...prev, surcharge: !prev.surcharge }))}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const renderActions = () => {
        return (
            <div className="w100 filter-modal-footer d-flex justify-content-between mt-4">
                <Button type="primary" onClick={() => saveFeeSettings()}>
                    <FormattedMessage id="save_changes" />
                </Button>
            </div>
        );
    };

    return (
        <Modal
            visible={visible}
            title={
                editingRule
                    ? intl.formatMessage({ id: "edit_partner_fee_rule" })
                    : intl.formatMessage({ id: "add_partner_fee_rule" })
            }
            footer={null}
            onCancel={onClose}
        >
            {renderOrderTypeSelect()}
            {renderFeeTypeSelect()}
            {renderFeeValueInput()}
            {renderFeeBasedOnSelect()}
            {renderExtraFeesSwitches()}
            {renderActions()}
        </Modal>
    );
};

export default PPCPModal;
